/**
 * @rob4lderman
 * may2019
 *
 */
import React from 'react';
import ForgotPasswordForm from '../comps/ForgotPasswordForm';
import SignInContainer from '../comps/SignInContainer';
import SignInFormBox from '../comps/SignInFormBox';
import {
    LoggerFactory, 
    config,
    sf,
} from '../utils';
import {
    withSessionState,
    withSessionDispatch,
    connect,
    useAlertWindow,
    useSignInEmail,
} from '../state';
import _ from 'lodash';
const logger = LoggerFactory( 'ForgotPasswordPage' );

/**
 *
 */
const ForgotPasswordPage: React.FC<any> = (props:any) => {
    const {
        resetPassword,
    } = props;
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ username ] = useSignInEmail();
    const { openAlertWindow, openAlertWindowForError } = useAlertWindow();

    logger.log( "render", { props } );

    const onSubmit = (event:any, username:string ): Promise<any> => {
        event.preventDefault();
        setIsLoading(true);
        return resetPassword( { username, url: config.RESET_PASSWORD_URL } )
            .then( sf.tap( _.partial( setIsLoading, false ) ))
            .then( (sf.tap( 
                () => openAlertWindow({
                        title: 'Email Sent',
                        text: `An email with a link for resetting your password was sent to ${ username }.\n\nClick the link to reset your password.`,
                    })
            )))
            .catch( sf.tap_throw( _.partial( setIsLoading, false ) ))
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    return (
        <SignInContainer>
            <SignInFormBox title="FORGOT PASSWORD">
               <ForgotPasswordForm
                    onSubmit={ onSubmit }
                    isLoading={ isLoading }
                />
            </SignInFormBox>
        </SignInContainer>
    );
};

export default connect(
    withSessionState,
    withSessionDispatch
)( ForgotPasswordPage );
