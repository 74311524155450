import _ from "lodash";
import moment from "moment";
import {
    Contact,
    FilmInfo,
    mapFilmInfoToTestResult
} from "../..";
import { timeFormat } from "../../../utils";
import { initLoadProfile } from '../../types';

export const stateSummaries:string = 'summaries' as const;
export const stateSummariesPagination:string = 'summariesPagination' as const;
export const stateCurrentModel:string = 'currentModel' as const;
export const stateGeneralInfo:string = 'generalInfo' as const;
export const stateCustomerInfo:string = 'customerInfo' as const;
export const stateFilmTestConfig:string = 'filmTestConfig' as const;

export const stateLoadingForm:string = 'handForm' as const;
export const stateLoadingSummary:string = 'handSummary' as const;
export const stateLoadingGetForm:string = 'handGetForm' as const;
export const stateLoadingGeneratePdf:string = 'generatePdf' as const;
export const stateLoadingClone:string = 'cloneTest' as const;

export const stateFilterTestSummaries:string = 'filterTestSummaries' as const;
export const stateSummariesSearchText:string = 'summariesSearchText' as const;

export const stateCurrentTestSummaryId:string = 'currentTestSummaryId' as const;

export const stateProducts:string = 'products' as const;

export const modelInitialState = ({
    [stateCurrentModel]: {
        [stateGeneralInfo]: {
            name: '',
            testDateTime: moment().format(timeFormat),
            notes: '',
            includeNotesOnReport: false,
            testTypeId: 2,
        },
        [stateCustomerInfo]: {
            id: undefined,
            name: '',
            location: '',
            distributor: '',
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            d365AccountNumber: '',
        },
        [stateFilmTestConfig]: {
            id: undefined,
            palletsPerDay: '',
            daysPerWeek: '',
            weightRange: '',
            palletHeight: '',
            palletLength: '',
            palletWidth: '',
            loadProfile: initLoadProfile(),
            hourlyWage: 20,
            rollChangeTime: 1.5,
            putAwayTime: 2.5
        }
    },
});

export const handTestInitialState = {
    ...modelInitialState,
    [stateProducts]: [],
    [stateCurrentTestSummaryId]: 0,
    [stateSummaries]: [],
    [stateSummariesPagination]: {
        page: 0, 
        rowsPerPage: 10 
    },
    isLoading: {
        [stateLoadingForm]: false,
        [stateLoadingSummary]: false,
        [stateLoadingGetForm]: false,
        [stateLoadingGeneratePdf]: false,
        [stateLoadingClone]: false,
    },
    [stateFilterTestSummaries]: false,
    [stateSummariesSearchText]: ''
} as const;

export const mapHandTestAnalysisPayload = ({ handTestAnalysis: hand, catAnalysis: cat }):any => ({
    id: cat.id ?? undefined,
    name: hand.generalInfo.name,
    testDateTime: moment( hand.generalInfo.testDateTime ).toISOString(),
    includeNotesOnReport: hand.generalInfo.includeNotesOnReport,
    notes: hand.generalInfo.notes,
    createdBy: hand.generalInfo.createdBy,
    createdDateTime: hand.generalInfo.createdDateTime,
    modifiedBy: hand.generalInfo.modifiedBy,
    modifiedDateTime: hand.generalInfo.modifiedDateTime,
    testTypeId: hand.generalInfo.testTypeId,

    customer: {
        id: hand.customerInfo.id ?? undefined,
        name: hand.customerInfo.name,
        distributor: hand.customerInfo.distributor,
        location: hand.customerInfo.location,
        contactName: hand.customerInfo.contactName,
        contactEmail: hand.customerInfo.contactEmail,
        contactPhone: hand.customerInfo.contactPhone,
        d365AccountNumber: hand.customerInfo.d365AccountNumber,
        contacts: _.chain( _.values( cat.contactsMap ) )
            .map( (contact:Contact) => _.omit( contact, 'strId' ) )
            .value(),
    },

    testConfiguration: {
        id: cat.testConfigurationId ?? undefined,
        testMethod: cat.testMethod,
        loadProfile: cat.palletInfo.loadProfile,
        equipmentModel: cat.equipmentInfo.equipmentModel,
        equipmentType: cat.equipmentInfo.equipmentType,
        equipmentManufacturer: cat.equipmentInfo.equipmentManufacturer,
        palletsPerDay: cat.palletInfo.palletsPerDay,
        daysPerWeek: cat.palletInfo.daysPerWeek,
        palletLength: cat.palletInfo.palletLength,
        palletWidth: cat.palletInfo.palletWidth,
        palletHeight: cat.palletInfo.palletHeight,
        weightRange: cat.palletInfo.weightRange,
        equipmentSerialNumber: cat.equipmentInfo.equipmentSerialNumber,
        carriageSpeed: cat.equipmentInfo.carriageSpeed,
        rotationSpeed: cat.equipmentInfo.rotationSpeed,
        ...hand.filmTestConfig,
    },

    testResults: _.chain( _.values( cat.filmInfoMap ) )
        .sortBy( (filmInfo:FilmInfo) => filmInfo.sequenceNumber ) 
        .map( mapFilmInfoToTestResult )
        .value()
});

export const mapGetHandTestPayload = (res:any) => ({
    [stateGeneralInfo]: {
        name: res.testName || res.name,
        testDateTime: moment(res.testDateTime).format(timeFormat),
        notes: res.notes,
        includeNotesOnReport: res.includeNotesOnReport,
        testTypeId: res.testTypeId,
    },
    [stateCustomerInfo]: {
        id: res.customer.id,
        name: res.customer.name,
        location: res.customer.location,
        distributor: res.customer.distributor,
        contactName: res.customer.contactName,
        contactEmail: res.customer.contactEmail,
        contactPhone: res.customer.contactPhone,
        d365AccountNumber: res.customer.d365AccountNumber,
    },
    [stateFilmTestConfig]: {
        id: res.testConfiguration.id,
        palletsPerDay: res.testConfiguration.palletsPerDay,
        daysPerWeek: res.testConfiguration.daysPerWeek,
        weightRange: res.testConfiguration.weightRange,
        palletHeight: res.testConfiguration.palletHeight,
        palletLength: res.testConfiguration.palletLength,
        palletWidth: res.testConfiguration.palletWidth,
        loadProfile: res.testConfiguration.loadProfile ?? initLoadProfile(),
        hourlyWage: res.testConfiguration.hourlyWage,
        rollChangeTime: res.testConfiguration.rollChangeTime,
        putAwayTime: res.testConfiguration.putAwayTime,

    }
});