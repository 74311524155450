/**
 * @rob4lderman
 * feb2020
 *
 * Loading page.
 */

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const Loading:React.FC<any> = (props:any) => {
    const {
        isLoading,
        ...rprops
    } = props;
    if ( !!! isLoading ) {
        return null;
    }
    return (
        <Box 
            style={{ 
                display: 'flex',
                flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            }}
            {...rprops}
            >
            <CircularProgress variant="indeterminate" />
        </Box>
    );
};

export default Loading;
