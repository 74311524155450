/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    h6: {
        fontWeight: 800,
    },
}));

const TitleH6:React.FC<any> = (props:any) => {
    const classes = useStyles();
    const {
        children,
        ...rprops
    } = props;
    return (
        <Typography 
            component="span" 
            variant="h6"
            classes={classes}
            {...rprops}
            >
            { children }
        </Typography>
    );
};

export default TitleH6;