/**
 * @rob4lderman
 * feb2020
 *
 * Redux.
 *
 */

import _ from 'lodash';
// -rx- import {
// -rx-     LoggerFactory,
// -rx- } from '../utils';
// -rx- const logger = LoggerFactory( 'selectors' );

/**
 * First arg to connect(...)(Comp).
 *
 * @return ({ state })
 */
export const withState = state => ({ state });

/**
 * 2nd arg to connect(...)(Comp).
 *
 * @return ({ dispatch })
 */
export const withDispatch = dispatch => ({ dispatch });

export const withAll = (...fns) => {
    return (...args) => _.reduce( 
        fns,
        (memo, fn) => ({ ...memo, ...fn(...args) }),
        {}
    );
};


/**
 *
 */
export const reduceReducers = ( reducers, initialState:any = null ) => 
    (state = initialState, action) => {
        const rv = _.reduce( 
            reducers, 
            (memo, reducer) => reducer(memo, action), 
            state 
        );
        return rv || null;
    }
    ;

/**
 *
 */
export const actionReducer = (actionType:string, fn, initialState:any = null) => 
    (state = initialState, action) => action.type === actionType 
        // -rx- ? logger.tap_last( 'actionReducer', { actionType, action }, fn( state, action ) )
        ? fn( state, action ) 
        : state 
        ;


export const payloadReducer = (state:any, action:any) => _.get( action, 'payload', state );

export const mergePayloadReducer = (state:any, action:any) => _.extend( {}, state, action.payload );