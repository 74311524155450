/**
 * @rob4lderman
 * feb2020
 *
 */
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formBox: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        // TODO: paddingBottom: theme.spacing(12),
        maxWidth: 800,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.text.primary,
        backgroundColor: 'white',
    },
    h6: {
        fontWeight: 800,
    },
}));

/**
 *
 */
const SignUpFormBox: React.FC<any> = (props:any) => {
    const {
        title,
        children,
    } = props;
    const classes = useStyles();
    return (
        <Box className={classes.formBox}>
            <Typography 
                component="h1" 
                variant="h6"
                align="left"
                className={classes.h6}
                >
                { title }
            </Typography>
            { children }
        </Box>
    );
};

export default SignUpFormBox;
