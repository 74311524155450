/**
 * @rob4lderman
 * mar2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    LoggerFactory,
    misc,
    sf,
} from '../utils';
import _ from 'lodash';
import { 
    useChangePasswordFormData,
} from '../state';
const logger = LoggerFactory( 'ChangePasswordForm' );

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
}));

interface Props {};
 
const ChangePasswordForm:React.FC<Props> = (props:Props) => {
    const [ changePasswordFormData, setChangePasswordFormData ] = useChangePasswordFormData();
    const classes = useStyles();
    logger.log( 'render', { changePasswordFormData, props });

    return (
        <Box style={{width:'100%'}}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="oldPassword"
                            label="Old Password"
                            type="password"
                            {...misc.getTextFieldError( changePasswordFormData.oldPasswordError )}
                            value={changePasswordFormData.oldPassword}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'oldPassword' ).extend( changePasswordFormData ),
                                    sf.lens( 'oldPasswordError' ).set( '' ),
                                    setChangePasswordFormData,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="newPassword"
                            label="New Password"
                            type="password"
                            {...misc.getTextFieldError( changePasswordFormData.newPasswordError )}
                            value={changePasswordFormData.newPassword}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'newPassword' ).extend( changePasswordFormData ),
                                    sf.lens( 'newPasswordError' ).set( '' ),
                                    setChangePasswordFormData,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="confirmNewPassword"
                            label="Confirm New Password"
                            type="password"
                            {...misc.getTextFieldError( changePasswordFormData.confirmNewPasswordError )}
                            value={changePasswordFormData.confirmNewPassword}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'confirmNewPassword' ).extend( changePasswordFormData ),
                                    sf.lens( 'confirmNewPasswordError' ).set( '' ),
                                    setChangePasswordFormData,
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ChangePasswordForm;