/**
 * @rob4lderman
 * mar2020
 */


import { createMuiTheme } from '@material-ui/core/styles';
// -rx- import purple from '@material-ui/core/colors/purple';
// -rx- import green from '@material-ui/core/colors/green';

// -rx- interface PaletteIntention {
// -rx-     light?: string;
// -rx-     main: string;
// -rx-     dark?: string;
// -rx-     contrastText?: string;
// -rx- }

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00A7E1',
        },
        secondary: {
            main: '#789C4A',
        },
        text: {
            primary: '#212721',
        },
    },
    typography: {
        fontFamily: [
          '"Work Sans"', 
          'sans-serif',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
        ].join(','),
    },
});

export default theme;