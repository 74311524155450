/**
 * @rob4lderman
 * feb2020
 */

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loading from './Loading';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TestSummary } from '../services/paragon';
import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
        // minWidth: 800,  // TODO: responsiveness
        tableLayout: 'fixed',
    },
    th: {
        fontWeight: 800,
        borderBottomWidth: 0,
        borderTopWidth: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.text.primary,
        borderRightWidth: 0,
        width: '17%',
        '&:last-child': {
            borderRightWidth: 1,
            width: '15%',
        },
    },
    td: {
        borderBottomWidth: 0,
        borderTopWidth: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.text.primary,
        borderRightWidth: 0,
        '&:last-child': {
            borderRightWidth: 1,
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

interface Props {
    testSummaries: TestSummary[];
    isLoadingTestSummaries: boolean;
    onClickTestSummary: Function;
    [key:string]: any;
};

/**
 * @param props testSummaries
 */
const TestSummaryTable:React.FC<Props> = (props:Props) => {
    const {
        testSummaries,
        isLoadingTestSummaries,
        onClickTestSummary,
        onClickSort,
        sortDirection,
        sortFieldName,
    } = props;
    const classes = useStyles();
    return (
            <TableContainer component={'div'}>
                <Table 
                    // -rx- stickyHeader
                    className={classes.table} 
                    // size="small" 
                    aria-label="test summaries"
                    >
                    <TableHead>
                        <TestSummaryHeader 
                            onClickSort={onClickSort}
                            sortDirection={sortDirection}
                            sortFieldName={sortFieldName}
                        />
                    </TableHead>
                    <TableBody>
                        {
                            _.chain( testSummaries )
                                .map( 
                                    (testSummary:TestSummary) => (
                                        <TestSummaryRow 
                                            key={_.toString( testSummary.id )}
                                            testSummary={testSummary} 
                                            onClickEdit={ (event:any) => onClickTestSummary( event, testSummary )}
                                        />
                                    )
                                )
                                .value()
                        }
                    </TableBody>
                </Table>
                <Loading isLoading={isLoadingTestSummaries} mt={8} mb={12} />
            </TableContainer>
    );
};

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#e5e5e5',
      },
    },
  }),
)(TableRow);

const StyledTableRowHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
        backgroundColor: '#e5e5e5',
    },
  }),
)(TableRow);

const headCells: any[] = [
    { id: 'testName', label: 'TEST NAME' },
    { id: 'userName', label: 'CREATOR' },
    { id: 'customerName', label: 'CUSTOMER' },
    { id: 'distributorName', label: 'DISTRIBUTOR' },
    { id: 'location', label: 'LOCATION' },
    { id: 'testDateTime', label: 'DATE' },
];

const TestSummaryHeader:React.FC<any> = (props:any) => {
    const {
        onClickSort,
        sortDirection,
        sortFieldName,
    } = props;
    const orderBy = sortFieldName;
    const order = sortDirection;
    const classes = useStyles();
    return (
        <StyledTableRowHeader>
            { 
                headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        className={classes.th}
                        sortDirection={orderBy === headCell.id ? order : false}
                        >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={ () => onClickSort(headCell.id) }
                            >
                            {headCell.label}
                            {orderBy === headCell.id 
                                ? (
                                    <span className={classes.visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                  ) 
                                : null}
                        </TableSortLabel>
                    </TableCell>
                ))
            }
        </StyledTableRowHeader>
    );
};

const TestSummaryRow:React.FC<any> = (props:any) => {
    const {
        testSummary,
        onClickEdit,
    } = props;
    const classes = useStyles();
    return (
        <StyledTableRow 
            key={_.toString( testSummary.id )}
            hover
            onClick={onClickEdit}
            >
            <TableCell className={classes.td}>
                {testSummary.testName}
            </TableCell>
            <TableCell className={classes.td}>
                {testSummary.userName}
            </TableCell>
            <TableCell className={classes.td}>
                {testSummary.customerName}
            </TableCell>
            <TableCell className={classes.td}>
                {testSummary.distributorName}
            </TableCell>
            <TableCell className={classes.td}>
                {testSummary.location}
            </TableCell>
            <TableCell className={classes.td}>
                {moment( testSummary.testDateTime ).format( 'MM/DD/YYYY' )}
            </TableCell>
            {/* -rx- 
            <TableCell>
                <Button
                    onClick={ onClickEdit }
                    style={{
                        margin: 0,
                    }}
                    >
                    Edit
                </Button>
            </TableCell>
            */}
        </StyledTableRow>
    );
};

export default TestSummaryTable;