/**
 * @rob4lderman
 * mar2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    LoggerFactory,
    misc,
    sf,
} from '../utils';
import _ from 'lodash';
import { 
    useUserProfile,
} from '../state';
const logger = LoggerFactory( 'UserProfileForm' );

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
}));

interface Props {};

 
const UserProfileForm:React.FC<Props> = (props:Props) => {
    const [ userProfile, setUserProfile ] = useUserProfile();
    const classes = useStyles();
    logger.log( 'render', { userProfile, props });
    if ( _.isNil( userProfile ) ) {
        return null;
    }
    return (
        <Box style={{width:'100%'}}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled={true}
                            label="Email Address"
                            name="email"
                            type="email"
                            value={userProfile.email}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'email' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            required
                            label="First Name"
                            value={userProfile.firstName}
                            // TODO: {...misc.getTextFieldError( firstNameError )}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 50 ),
                                    sf.lens( 'firstName' ).extend( userProfile ),
                                    setUserProfile,
                                    // TODO: _.partial( setFirstNameError, '' )
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            value={userProfile.lastName}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    sf.lens( 'lastName' ).extend( userProfile ),
                                    misc.truncateAt( 50 ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="company"
                            label="Company"
                            autoComplete="off" // TODO
                            value={userProfile.company}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 100 ),
                                    sf.lens( 'company' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="street"
                            label="Street Address"
                            autoComplete="street-address" 
                            value={userProfile.street}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 150 ),
                                    sf.lens( 'street' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="city"
                            label="City"
                            autoComplete="address-level2" 
                            value={userProfile.city}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 50 ),
                                    sf.lens( 'city' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            name="state"
                            label="State"
                            autoComplete="address-level1"
                            value={userProfile.state}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 3 ),
                                    sf.lens( 'state' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            name="postalCode"
                            label="Zip"
                            autoComplete="postal-code" 
                            value={userProfile.postalCode}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 10 ),
                                    sf.lens( 'postalCode' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            name="country"
                            label="Country"
                            autoComplete="country"
                            value={userProfile.country}
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.truncateAt( 3 ),
                                    sf.lens( 'country' ).extend( userProfile ),
                                    setUserProfile,
                                )
                            }
                        />
                    </Grid>
               </Grid>
            </form>
        </Box>
    );
};

export default UserProfileForm;