import _ from 'lodash';
import { FilmInfo, initFilmInfo } from '../state';
import { FIELD_DESCRIPTION_BY_SEQUENCE, REGEX_PHONE_NUMBER } from './const';
import * as sf from './sf';

export const isEmpty = (text:string) => _.isEmpty( _.trim( text ) );

export const validateTrue = ( isValid:boolean, setErrorFn:Function ): boolean => {
    if ( !!! isValid ) {
        setErrorFn();
    }
    return isValid;
};

export const validateNotEmpty = ( text:string, setErrorFn:Function ): boolean => {
    return validateTrue( !!! isEmpty( text ), setErrorFn );
};

export const getTextFieldError = (helperText:string) => {
    return _.isEmpty( helperText )
        ? {}
        : { error: true, helperText }
        ;
};

export const onChangeTextField = (setFn:(val:any) => any, setErrorFn:(val:any) => any) => 
    _.flow( 
        sf.lens( 'target.value' ).get,
        setFn,
        _.partial( setErrorFn, '' )
    )
    ;
 

export const withMaxLen = (setFn:Function, length:number) => (val:string) => 
    setFn( _.truncate( val, { length, omission:'' } ) )
    ;

export const truncateAt = (length:number) => (val:string) => {
    return _.truncate( val, { length, omission:'' } );
};

export const assertAllTrue = (vals:boolean[]): boolean[] => {
    const isValid = _.reduce( vals, (memo:boolean, val:boolean) => memo && val, true );
    if ( !!! isValid ) {
        throw Error();
    }
    return vals;
};
 
export const stripNonNumericChars = (str:string): string => {
    return !!! _.isString(str)
        ? str
        : str.replace(/[^0-9.]/g, '')
        ;
};

export const textInputToNumber = (str:string): string => {
    if ( _.isNumber( str ) ) {
        return str ;
    }
    const str1 = stripNonNumericChars( str );
    // this method is called from TextInput.onChange.  We can't convert
    // it to a number bc then it would strip off the ".0" from "47.0", and
    // make it impossible to input something like "47.05".
    // -rx- return _.isEmpty( str1 ) ? null : _.toNumber( str1 );
    return _.isEmpty( str1 ) ? null : str1 ;
};

export const toNumberOrNull = (str:string): number => {
    if ( _.isNumber( str ) ) {
        return str as number;
    }
    const str1 = stripNonNumericChars( str );
    return _.isEmpty( str1 ) ? null : _.toNumber( str1 );
};

export const numberToString = (n:number) => {
    return _.isNil( n )
        ? ''
        : _.toString( n )
        ;
};

export const checkMinNumber = (n:string):number => +n <= 0 ? null : +n;

export const truncateDecimals = (num):string => {
    const regex = new RegExp('^-?\\d+(?:.\\d{0,' + (3 || -1) + '})?');
    return num.toString().match(regex)?.[0];
}

const mapFilm = ( data:FilmInfo[] ):number[] => data.map( ( x:FilmInfo ) => x.sequenceNumber );

const getDifference = ( numbers:number[]):Number[] => _.difference( [1, 2, 3], numbers );

const addMissingSequenceObjects = ( missingNumbers:number[]):FilmInfo[] =>
    missingNumbers.map( ( x:number ) => initFilmInfo( x ) );

export const findMissingSequenceNumber = _.flow( [ mapFilm, getDifference, addMissingSequenceObjects ] );

export const getValidPhoneNumber = ( currentValue:string ) => ( newValue:string ):string =>
    !!newValue.match( REGEX_PHONE_NUMBER )?.length
        ? newValue?.trim()
        : currentValue;

export const getDescription = ( sequenceNumber:number, description:string = null ):string =>
    _.isNil( description )
        ? FIELD_DESCRIPTION_BY_SEQUENCE[ sequenceNumber - 1 ]
        : description;


