/**
 * @rob4lderman
 * feb2020
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Loading from './Loading';
import { makeStyles } from '@material-ui/core/styles';
import { TestSummary } from '../services/paragon';
import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    rowBox: {
        display: 'flex',
        padding: theme.spacing(2),
    },
    h6: {
        fontWeight: 800,
    },
    rightBox: {
        paddingLeft: theme.spacing(0.5),
        textAlign: 'right',
    },
    microtext: {
        fontSize: 8,
        fontWeight: 800,
    },
    gridRow: {
        '&:nth-of-type(even)': {
          backgroundColor: '#e5e5e5',
        },
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#e5e5e5', // theme.palette.text.secondary,
    }
}));

interface Props {
    testSummaries: TestSummary[];
    isLoadingTestSummaries: boolean;
    onClickTestSummary: Function;
    [key:string]: any;
};

/**
 * @param props testSummaries
 */
const TestSummaryTableXs:React.FC<Props> = (props:Props) => {
    const {
        testSummaries,
        isLoadingTestSummaries,
        onClickTestSummary,
    } = props;
    const classes = useStyles();
    return (
        <Box>
            <Grid container spacing={2}>
                {
                     _.chain( testSummaries )
                         .map( 
                             (testSummary:TestSummary) => (
                                <Grid 
                                    key={_.toString( testSummary.id )}
                                    item 
                                    xs={12}
                                    className={classes.gridRow}
                                    >
                                    <TestSummaryRowXs
                                        key={_.toString( testSummary.id )}
                                        testSummary={testSummary} 
                                        onClickEdit={ (event:any) => onClickTestSummary( event, testSummary )}
                                    />
                                </Grid>
                            )
                        )
                        .value()
                }
            </Grid>
            <Loading isLoading={isLoadingTestSummaries} mt={8} mb={12} />
        </Box>
    );
};

const FieldAndLabel:React.FC<any> = (props:any) => {
    const {
        label,
        value,
        ...rprops
    } = props;
    const classes = useStyles();
    return (
        <Box {...rprops}>
            <Typography variant="body2">
                {value}
            </Typography>
            <Typography className={classes.microtext} variant="body2">
                {label}
            </Typography>
        </Box>
    );
};

const TestSummaryRowXs:React.FC<any> = (props:any) => {
    const {
        testSummary,
        onClickEdit,
    } = props;
    const classes = useStyles();
    return (
        <Box 
            display={'flex'} 
            flexDirection={'column'}
            onClick={onClickEdit}
            className={classes.rowBox} 
            >
            <Box display="flex" justifyContent={'space-between'}>
                <FieldAndLabel
                    value={testSummary.testName}
                    label={'TEST NAME'}
                />
                <FieldAndLabel 
                    className={classes.rightBox}
                    value={moment( testSummary.testDateTime ).format( 'MM/DD/YYYY' )}
                    label={'TEST DATE'}
                />
            </Box>
            <Box mt={1} display="flex" justifyContent={'space-between'}>
                <FieldAndLabel
                    value={testSummary.customerName}
                    label={'CUSTOMER'}
                />
                <FieldAndLabel 
                    className={classes.rightBox}
                    value={testSummary.location}
                    label={'LOCATION'}
                />
            </Box>
            <Box mt={1} display="flex" justifyContent={'space-between'}>
                <FieldAndLabel
                    value={testSummary.distributorName}
                    label={'DISTRIBUTOR'}
                />
                <FieldAndLabel 
                    className={classes.rightBox}
                    value={testSummary.userName}
                    label={'CREATOR'}
                />
            </Box>
        </Box>
    );
};

export default TestSummaryTableXs;