import { 
    createStore, 
    combineReducers, 
    applyMiddleware, 
} from 'redux';

import thunk from 'redux-thunk';
import ModelReducer from './model';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const rootReducer = combineReducers( {
    model: ModelReducer,
});

export const store = createStore( 
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
));


