/**
 * @rob4lderman
 * feb2020
 *
 */

import React from 'react';
import {
    useState,
} from 'react';
import Box from '@material-ui/core/Box';
import TextField from '../material/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    withUserState,
    connect,
    useSignInEmail,
} from '../state';
import {
    LoggerFactory,
    misc,
} from '../utils';
import Button from '../material/Button';
import _ from 'lodash';
const logger = LoggerFactory('ForgotPasswordForm');

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

interface Props {
    onSubmit: (event:Event, username:string) => Promise<any>;
    isLoading?: boolean;
};

const ForgotPasswordForm: React.FC<Props> = (props:Props) => {
    const {
        onSubmit,
        isLoading = false,
    } = props;
    const [ username, setUsername ] = useSignInEmail();
    const [ usernameError, setUsernameError ] = useState( '' );
    const classes = useStyles();

    logger.log( 'render', { props });

    const validate = (username:string): string => {
        logger.log( "ForgotPasswordForm.validate", { username } );
        const validated = [
            misc.validateNotEmpty( username, _.partial( setUsernameError, 'Required' ) ),
        ];
        misc.assertAllTrue( validated )
        return username;
    };

    const _onSubmit = (event:any) => {
        event.preventDefault();
        return Promise.resolve(username)
            .then( validate )
            .then( _.partial( onSubmit, event ) )
            .catch( logger.logErrorFn('onSubmit') )
            ;
    };

    return (
        <form className={classes.form} noValidate>
             <TextField
                 margin="normal"
                 // required
                 fullWidth
                 label="Email"
                 name="username"
                 value={username}
                 type="email"
                 onChange={ misc.onChangeTextField( setUsername, setUsernameError )}
                 {...misc.getTextFieldError( usernameError )}
             />
             <Box mt={3}></Box>
             <Button
                 type="submit"
                 fullWidth
                 onClick={ _onSubmit }
                 isLoading={isLoading}
                 >
                 Send
             </Button>
        </form>
    );
};


export default connect(
    withUserState
)( ForgotPasswordForm );
