/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { 
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import {
    LoggerFactory,
    misc,
    sf,
} from '../utils';
import _ from 'lodash';
import {
    withFormOptionsState,
    connect,
    useEquipmentInfo,
} from '../state';
import { 
    Manufacturer,
    EquipmentType,
    Equipment,
} from '../types';
import ManufacturerAutoComplete from './ManufacturerAutoComplete';
import EquipmentTypeAutoComplete from './EquipmentTypeAutoComplete';
import EquipmentListAutoComplete from './EquipmentListAutoComplete';
const logger = LoggerFactory( 'EquipmentInfoForm' );

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        // -rx- marginTop: theme.spacing(3),
    },
}));

interface Props {
    equipmentManufacturers: Manufacturer[];
    equipmentTypes: EquipmentType[];
    equipmentList: Equipment[];
};

const EquipmentInfoForm:React.FC<Props> = (props:Props) => {
    const {
        equipmentManufacturers,
        equipmentTypes,
        equipmentList,
    } = props;
    const [ _equipmentInfo, setEquipmentInfo ] = useEquipmentInfo();
    const classes = useStyles();
    logger.log( 'render', { props });
    return (
        <Box style={{width:'100%'}}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    {/* first column */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ManufacturerAutoComplete
                                    value={ _equipmentInfo.equipmentManufacturer }
                                    manufacturers={equipmentManufacturers}
                                    onChange={ 
                                        _.flow(
                                            sf.lens( 'target.value' ).get,
                                            sf.lens( 'equipmentManufacturer' ).extend( _equipmentInfo ),
                                            sf.tap( setEquipmentInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EquipmentListAutoComplete
                                    equipmentList={
                                        _.filter( 
                                            equipmentList, 
                                            (equipment:Equipment) => equipment.manufacturer.name === _equipmentInfo.equipmentManufacturer
                                        )
                                    }
                                    value={ _equipmentInfo.equipmentModel }
                                    onChange={ 
                                        _.flow(
                                            sf.lens( 'target.value' ).get,
                                            sf.lens( 'equipmentModel' ).extend( _equipmentInfo ),
                                            sf.tap( setEquipmentInfo ),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* second column */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="equipmentSerialNumber"
                                    label="Serial Number"
                                    value={ _equipmentInfo.equipmentSerialNumber }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            _.trim,
                                            sf.lens( 'equipmentSerialNumber' ).extend( _equipmentInfo ),
                                            sf.tap( setEquipmentInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EquipmentTypeAutoComplete
                                    equipmentTypes={equipmentTypes}
                                    value={ _equipmentInfo.equipmentType }
                                    onChange={ 
                                        _.flow(
                                            sf.lens( 'target.value' ).get,
                                            sf.lens( 'equipmentType' ).extend( _equipmentInfo ),
                                            sf.tap( setEquipmentInfo ),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* third column */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="carriageSpeed"
                                    label="Carriage Speed"
                                    value={  _equipmentInfo.carriageSpeed }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            sf.lens( 'carriageSpeed' ).extend( _equipmentInfo ),
                                            sf.tap( setEquipmentInfo ),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="rotationSpeed"
                                    label="RPM"
                                    value={ _equipmentInfo.rotationSpeed }
                                    onChange={ 
                                        _.flow( 
                                            sf.lens( 'target.value' ).get,
                                            misc.textInputToNumber,
                                            sf.lens( 'rotationSpeed' ).extend( _equipmentInfo ),
                                            sf.tap( setEquipmentInfo ),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};


export default connect(
    withFormOptionsState,
)( EquipmentInfoForm );