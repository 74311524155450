/**
 * @rob4lderman
 * feb2020
 *
 */
import React from 'react';
import SignInForm  from '../comps/SignInForm';
import SignInFormBox  from '../comps/SignInFormBox';
import SignInContainer from '../comps/SignInContainer';
import {
    LoggerFactory,
    sf,
} from '../utils';
import {
    withSessionState,
    withSessionDispatch,
    connect,
    useAlertWindow,
} from '../state';
import {
    LoginRequest,
} from '../types';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import * as paragon from '../services/paragon';
const logger = LoggerFactory( 'SignInPage' );

/**
 *
 */
const SignInPage: React.FC<any> = (props:any) => {
    const {
        login,
    } = props;
    const [ isLoading, setIsLoading ] = React.useState(false);
    const { openAlertWindowForError } = useAlertWindow();

    logger.log( "render", { props } );

    const onSubmit = (event:any, data:LoginRequest ): Promise<any> => {
        event.preventDefault();
        setIsLoading(true);
        return login( data )
            .then( sf.tap( _.partial( setIsLoading, false ) ))
            .then( sf.tap( () => logger.log( 'SignInPage.redirecting... ', { isAuthz: paragon.isAuthz() })))
            .then( () => props.history.replace( _.get( props, 'location.state.fromLocation', '/machine-tests' ) ) )
            .catch( sf.tap_throw( _.partial( setIsLoading, false ) ))
            .catch( sf.tap( openAlertWindowForError ) )
            ;
    };

    return (
        <SignInContainer>
            <SignInFormBox title="PLEASE SIGN IN">
                <SignInForm
                    onSubmit={ onSubmit }
                    isLoading={ isLoading }
                />
                <Box 
                    mt={2}
                    textAlign="center" 
                    >
                    <Link 
                        // -rx- href="/forgot-password" 
                        onClick={() => props.history.push('/forgot-password')}
                        variant="body2"
                        >
                        I forgot my password
                    </Link>
                    {/* 
                    <Typography variant="body2">
                        New to Paragon Films Portal? <Link onClick={() => props.history.push('/signup')}>Register</Link>
                    </Typography>
                    */}
                </Box>
            </SignInFormBox>
        </SignInContainer>
    );
};


export default connect(
    withSessionState,
    withSessionDispatch
)( SignInPage );
