/**
 * @rob4lderman
 * feb2020
 */

import React from 'react';
import {
    LoggerFactory,
    config,
} from '../utils';
import {
    Route,
    Redirect,
} from 'react-router-dom';
import * as paragon from '../services/paragon';

const logger = LoggerFactory('AuthRoute');

interface Props {
    component:any;
    [key:string]: any;
};

/**
 * <AuthRoute path="/carriers" component={ChatsPage} />
 */
const AuthRoute:React.FC<Props> = (props:any) => {
    const { 
        component: Component, 
        ...rprops
    } = props;
    logger.log( "AuthRoute", { props} );
    return (
        <Route
            {...rprops}
            render={
                props => paragon.isAuthz()   // reach into the state as it is right now.
                      ? <Component {...props} />
                      : <Redirect to={{ pathname: config.LOGIN_PATHNAME, state: { fromLocation: props.location } }} />
            }
        />
    );
};
 
export default AuthRoute;