import React from 'react';
import './App.css';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import LaunchPage from './pages/LaunchPage';
import {
    routeEditMachine,
    sf,
} from './utils';
import { 
    withSessionState,
    withSessionDispatch,
    connect,
} from './state';
import AuthRoute from './comps/AuthRoute';
import SavedTestsPage from './pages/SavedTestsPage';
import SavedHandTestsPage from './pages/SavedHandTestsPage';
import EditTestPage from './pages/EditTestPage';
import EditHandTestPage from './pages/EditHandTestPage';
import UserProfilePage from './pages/UserProfilePage';
import ViewPdfLoadingPage from './pages/ViewPdfLoadingPage';
import { routeEditHand } from './utils/const';

interface Props {
    onLaunch: () => Promise<any>;
};

const App: React.FC<Props> = ( { onLaunch }:Props ) => {
    const [ isLoading, setIsLoading] = React.useState( true );

    React.useEffect( 
        () => {
            onLaunch()
                .then( sf.pause( 200 ) )
                .then( () => setIsLoading( false ) )
                .catch( () => setIsLoading( false ) );
        }, 
        []
    );

    if ( isLoading ) return <LaunchPage />;
    return (
        <BrowserRouter>
            <Switch>
                <Redirect from="/" to="/machine-tests" exact />
                <AuthRoute path="/machine-tests" component={SavedTestsPage} exact />
                <AuthRoute path="/hand-tests" component={SavedHandTestsPage} exact />
                <AuthRoute path={ `/${routeEditMachine}` } component={EditTestPage} exact />
                <AuthRoute path={ `/${routeEditHand}` } component={EditHandTestPage} exact />
                <AuthRoute path="/user-profile" component={UserProfilePage} exact />
                <Route path="/view-pdf" component={ViewPdfLoadingPage} exact />
                <Route path="/signin" component={SignInPage} />
                <Route path="/signup" component={SignUpPage} />
                <Route path="/forgot-password" component={ForgotPasswordPage} />
                <Route path="/reset-password" component={ResetPasswordPage} />
                <Redirect to="/signin" />
            </Switch>
        </BrowserRouter>
    );
};

export default connect(
    withSessionState,
    withSessionDispatch,
)( App );
