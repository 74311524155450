import _ from 'lodash';
import {
    Contact,
    CatAnalysis,
    CatAnalyses,
    FilmInfo,
    initContact,
    TestPayload,
    TestResult,
} from './types';
import {
    LoggerFactory,
} from '../utils';
import { 
    Manufacturer, 
    TestMethod, 
    TestSummary 
} from '../types';
import moment from 'moment';
import { findMissingSequenceNumber, getDescription } from '../utils/misc';
const logger = LoggerFactory('catModel');

export const asIdStr = (id:number) => `id-${id}` ;

/**
 * @return newCatAnalyses - shallow clone of catAnalyses (to trigger renders)
 */
export const setCurrentCatAnalysis = (catAnalyses:CatAnalyses, action:any) => {
    const newCatAnalyses:CatAnalyses = _.clone( catAnalyses );
    const catAnalysis:CatAnalysis = action.payload;
    newCatAnalyses.catAnalysisMap[ catAnalysis.strId ] = catAnalysis;
    newCatAnalyses.currentStrId = catAnalysis.strId;
    logger.log( 'setCurrentCatAnalysis', { newCatAnalyses });
    return newCatAnalyses;
};


export const getCurrentCatAnalysis = (catAnalyses:CatAnalyses): CatAnalysis => {
    return _.get( catAnalyses.catAnalysisMap, catAnalyses.currentStrId, null );
};

export const createContact = (catAnalyses:CatAnalyses): CatAnalyses => {
    const newCatAnalyses:CatAnalyses = _.cloneDeep( catAnalyses );
    const currentCatAnalysis = getCurrentCatAnalysis( newCatAnalyses );
    const strId:string = asIdStr( _.keys( currentCatAnalysis.contactsMap ).length + 1 ); // TODO: avoid collision w/ existing contact IDs
    currentCatAnalysis.contactsMap[ strId ] = initContact(strId);
    return replaceCatAnalysis( newCatAnalyses, currentCatAnalysis );
};

export const deleteContact = (catAnalyses:CatAnalyses, action:any): CatAnalyses => {
    const newCatAnalyses:CatAnalyses = _.cloneDeep( catAnalyses ); // TODO?
    const contact:Contact = action.payload;
    return _.chain( newCatAnalyses )
        .thru( getCurrentCatAnalysis )
        .thru( 
            (catAnalysis:CatAnalysis) => {
                delete catAnalysis.contactsMap[ contact.strId ];
                return catAnalysis;
            }
        )
        .thru( _.partial( replaceCatAnalysis, newCatAnalyses ) )
        .value()
        ;
};

export const replaceContact = (catAnalysis:CatAnalysis, contact:Contact): CatAnalysis => {
    catAnalysis.contactsMap[ contact.strId ] = contact;
    return catAnalysis;
};

export const replaceCatAnalysis = (catAnalyses:CatAnalyses, catAnalysis:CatAnalysis): CatAnalyses => {
    catAnalyses.catAnalysisMap[ catAnalysis.strId ] = catAnalysis;
    return catAnalyses;
};

export const updateContactFast = (catAnalyses:CatAnalyses, action:any): CatAnalyses => {
    return _.chain( catAnalyses )
        .thru( getCurrentCatAnalysis )
        .thru( _.partial( replaceContact, _, action.payload as Contact ) )
        .thru( _.partial( replaceCatAnalysis, catAnalyses ) )
        .value()
        ;
};

export const replaceFilmInfo = (catAnalysis:CatAnalysis, filmInfo:FilmInfo ): CatAnalysis => {
    catAnalysis.filmInfoMap[ filmInfo.strId ] = filmInfo;
    return catAnalysis;
};

export const updateFilmInfoFast = (catAnalyses:CatAnalyses, action:any): CatAnalyses => {
    return _.chain( catAnalyses )
        .thru( getCurrentCatAnalysis )
        .thru( _.partial( replaceFilmInfo, _, action.payload as FilmInfo) )
        .thru( _.partial( replaceCatAnalysis, catAnalyses ) )
        .value()
        ;
};

export const updateCatAnalysisFieldFast = (fieldName:string) => (catAnalyses:CatAnalyses, action:any): CatAnalyses => {
    const currentCatAnalysis = getCurrentCatAnalysis(catAnalyses);
    currentCatAnalysis[fieldName] = action.payload;
    return catAnalyses;
};

export const isManufacturerParagon = (manufacturer:Manufacturer) => manufacturer.name === "Paragon Films";

export const mapCatAnalysisToTestPayload = (cat:CatAnalysis): TestPayload => {
    return {
        id: _.isNil( cat.id ) ? undefined : cat.id,
        name: cat.generalInfo.name,
        testDateTime: moment( cat.generalInfo.testDateTime ).toISOString(),
        notes: cat.generalInfo.notes,
        testTypeId: cat.generalInfo.testTypeId,
        includeNotesOnReport: cat.generalInfo.includeNotesOnReport,
        createdBy: cat.createdBy,
        createdDateTime: cat.createdDateTime,
        modifiedBy: cat.modifiedBy,
        modifiedDateTime: cat.modifiedDateTime,
        customer: {
            id: _.isNil( cat.customerInfo.id ) ? undefined : cat.customerInfo.id,
            name: cat.customerInfo.name,
            distributor: cat.customerInfo.distributor,
            location: cat.customerInfo.location,
            contactName: cat.customerInfo.contactName,
            contactEmail: cat.customerInfo.contactEmail,
            contactPhone: cat.customerInfo.contactPhone,
            d365AccountNumber: cat.customerInfo.d365AccountNumber,
            contacts: _.chain( _.values( cat.contactsMap ) )
                .map( (contact:Contact) => _.omit( contact, 'strId' ) )
                .value()
                ,
        },
        testConfiguration: {
            id: _.isNil( cat.testConfigurationId ) ? undefined : cat.testConfigurationId,
            testMethod: cat.testMethod,
            loadProfile: cat.palletInfo.loadProfile,
            equipmentModel: cat.equipmentInfo.equipmentModel,
            equipmentType: cat.equipmentInfo.equipmentType,
            equipmentManufacturer: cat.equipmentInfo.equipmentManufacturer,
            palletsPerDay: cat.palletInfo.palletsPerDay,
            daysPerWeek: cat.palletInfo.daysPerWeek,
            palletLength: cat.palletInfo.palletLength,
            palletWidth: cat.palletInfo.palletWidth,
            palletHeight: cat.palletInfo.palletHeight,
            weightRange: cat.palletInfo.weightRange,
            hourlyWage: cat.palletInfo.hourlyWage,
            putAwayTime: cat.palletInfo.putAwayTime,
            rollChangeTime: cat.palletInfo.rollChangeTime,
            equipmentSerialNumber: cat.equipmentInfo.equipmentSerialNumber,
            carriageSpeed: cat.equipmentInfo.carriageSpeed,
            rotationSpeed: cat.equipmentInfo.rotationSpeed,
        },
        testResults: _.chain( _.values( cat.filmInfoMap ) )
            .sortBy( (filmInfo:FilmInfo) => filmInfo.sequenceNumber ) 
            .map( mapFilmInfoToTestResult )
            .value()
        ,
    };
};

export const mapHandAnalysisToTestPayload = ({ handTestAnalysis:hand, catAnalysis:cat }) => {
    return {
        id: hand.id || 0,
        handType: true,
        name: hand.generalInfo.name,
        includeNotesOnReport: hand.generalInfo.includeNotesOnReport,
        testDateTime: moment( hand.generalInfo.testDateTime ).toISOString(),
        createdBy: hand.createdBy,
        createdDateTime: hand.createdDateTime,
        modifiedBy: hand.modifiedBy,
        modifiedDateTime: hand.modifiedDateTime,    
        testTypeId: 2,
        customer: {
            id: hand.customerInfo.id || 0,
            name: hand.customerInfo.name,
            location: hand.customerInfo.location,
            distributor: hand.customerInfo.distributor,
            contactName: hand.customerInfo.contactName,
            contactEmail: hand.customerInfo.contactEmail,
            contactPhone: hand.customerInfo.contactPhone,
            d365AccountNumber: hand.customerInfo.d365AccountNumber,
        },
        testConfiguration: {
            id: cat.testConfigurationId || 0,
            testMethod: cat.testMethod,
            loadProfile: cat.palletInfo.loadProfile.id === 0 ? hand.filmTestConfig.loadProfile : cat.palletInfo.loadProfile,
            equipmentModel: cat.equipmentInfo.equipmentModel,
            equipmentType: cat.equipmentInfo.equipmentType,
            equipmentManufacturer: cat.equipmentInfo.equipmentManufacturer,
            palletsPerDay: cat.palletInfo.palletsPerDay || hand.filmTestConfig.palletsPerDay,
            daysPerWeek: cat.palletInfo.daysPerWeek || hand.filmTestConfig.daysPerWeek,
            palletLength: cat.palletInfo.palletLength || hand.filmTestConfig.palletLength,
            palletWidth: cat.palletInfo.palletWidth || hand.filmTestConfig.palletWidth,
            palletHeight: cat.palletInfo.palletHeight || hand.filmTestConfig.palletHeight,
            weightRange: cat.palletInfo.weightRange || hand.filmTestConfig.weightRange,
            hourlyWage: cat.palletInfo.hourlyWage || hand.filmTestConfig.hourlyWage,
            putAwayTime: cat.palletInfo.putAwayTime || hand.filmTestConfig.putAwayTime,
            rollChangeTime: cat.rollChangeTime || hand.filmTestConfig.rollChangeTime,
            equipmentSerialNumber: cat.equipmentInfo.equipmentSerialNumber,
            carriageSpeed: cat.equipmentInfo.carriageSpeed,
            rotationSpeed: cat.equipmentInfo.rotationSpeed,
        },
        testResults: _.chain( _.values( cat.filmInfoMap ) )
            .sortBy( (filmInfo:FilmInfo) => filmInfo.sequenceNumber ) 
            .map( mapFilmInfoToTestResult )
            .value()
        ,
    };
};

export const isEmptyTestResult = (testResult:any) => _.isEmpty( testResult.productManufacturer ) || _.isEmpty( testResult.productName );


export const mapFilmInfoToTestResult = (filmInfo:FilmInfo): TestResult => {
    return _.extend( 
        _.omit( filmInfo, 'strId' ),
        { id: _.isNil( filmInfo.id ) ? undefined : filmInfo.id }
    );
};

const mapTestResultToFilmInfo = (testResult:TestResult): FilmInfo => {
    return _.extend( testResult,
        { 
            strId: asIdStr( testResult.sequenceNumber ),  
            description: getDescription( testResult.sequenceNumber, testResult.description )
        } );
};

export const mapTestPayloadToCatAnalysis = (test:TestPayload): CatAnalysis => {
    return {
        id: test.id,
        strId: asIdStr( test.id ),
        generalInfo: {
            name: test.name,
            testDateTime: moment( test.testDateTime ).toDate(),
            notes: test.notes,
            includeNotesOnReport: test.includeNotesOnReport,
            testTypeId: test.testTypeId,
        },
        createdBy: test.createdBy,
        createdDateTime: test.createdDateTime,
        modifiedBy: test.modifiedBy,
        modifiedDateTime: test.modifiedDateTime,
 
        testConfigurationId: test.testConfiguration.id,

        customerInfo: {
            id: test.customer.id,
            name: test.customer.name,
            distributor: test.customer.distributor,
            location: test.customer.location,
            contactName: test.customer.contactName,
            contactEmail: test.customer.contactEmail,
            contactPhone: test.customer.contactPhone,
            d365AccountNumber: test.customer.d365AccountNumber,
        },

        contactsMap: _.chain( test.customer.contacts )
            .map( (contact:Contact) => _.extend( {}, contact, { strId: asIdStr( contact.id ) } ) )
            .keyBy( 'strId' )
            .value()
            ,

        palletInfo: {
            palletsPerDay: test.testConfiguration.palletsPerDay,
            daysPerWeek: test.testConfiguration.daysPerWeek,
            palletHeight: test.testConfiguration.palletHeight,
            palletLength: test.testConfiguration.palletLength,
            palletWidth: test.testConfiguration.palletWidth,
            weightRange: test.testConfiguration.weightRange,
            loadProfile: test.testConfiguration.loadProfile,
            hourlyWage: test.testConfiguration.hourlyWage,
            putAwayTime: test.testConfiguration.putAwayTime,
            rollChangeTime: test.testConfiguration.rollChangeTime,
        },
        equipmentInfo: {
            equipmentManufacturer: test.testConfiguration.equipmentManufacturer,
            equipmentType: test.testConfiguration.equipmentType,
            equipmentModel: test.testConfiguration.equipmentModel,
            equipmentSerialNumber: test.testConfiguration.equipmentSerialNumber,
            carriageSpeed: test.testConfiguration.carriageSpeed,
            rotationSpeed: test.testConfiguration.rotationSpeed,
        },
        testMethod: test.testConfiguration.testMethod ?? {} as TestMethod,

        filmInfoMap: _.chain( test.testResults )
            .map( mapTestResultToFilmInfo )
            .thru( (filmInfos:FilmInfo[]) => filmInfos.length < 3
                ? [ ...filmInfos, ...findMissingSequenceNumber(filmInfos) ]
                : filmInfos 
            )
            .keyBy( 'strId' )
            .value()
    };
};


export const matchTestSummary = (searchText:string, testSummary:TestSummary): boolean => {
    if ( _.isEmpty( _.trim( searchText ) ) ) {
        return true;
    }
    const lowerSearchText = _.toLower(searchText);
    return _.includes( _.toLower( testSummary.userName ), lowerSearchText )
        || _.includes( _.toLower( testSummary.customerName ), lowerSearchText )
        || _.includes( _.toLower( testSummary.testName ), lowerSearchText )
        || _.includes( _.toLower( testSummary.distributorName ), lowerSearchText )
        || _.includes( _.toLower( testSummary.location ), lowerSearchText )
        ;
};

export const isTestSummaryCreatedBy = (testSummary:TestSummary, createdBy:string): boolean => {
    return _.toLower( _.get( testSummary, 'createdBy', 'x' ) ) === _.toLower( createdBy );
};

export const findTestSummaryById = (testSummaries:TestSummary[], id:number): TestSummary => {
    logger.log( 'findTestSummaryById', { id });
    return _.find( testSummaries, (testSummary:TestSummary) => testSummary.id === id );
};

export const memoizedFindTestSummaryById = _.memoize( 
    findTestSummaryById,
    (testSummaries:TestSummary[], id:number): string => `${testSummaries}-${id}`,
);

export const isWorkingCatAnalysis = (catAnalysis:CatAnalysis): boolean => _.get( catAnalysis, 'strId' ) === 'working';

