/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import Button from '@material-ui/core/Button';

const TextButton:React.FC<any> = (props:any) => {
    const {
        children,
        ...rprops
    } = props;
    return (
        <Button {...rprops}>
            {children}
        </Button>
    );
};

export default TextButton;