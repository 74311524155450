import * as actionTypes from './types';
import { HandTestSummary } from '../models';
import { Product } from '../../../types';

export const updateGeneralInfoField = (key:string, payload) => ({
    type: actionTypes.UPDATE_GENERAL_INFO,
    key,
    payload
});

export const updateCustomerInfoField = (key:string, payload:any) => ({
    type: actionTypes.UPDATE_CUSTOMER_INFO,
    key,
    payload
});

export const updateFilmTestConfig = (key:string, payload:any) => ({
    type: actionTypes.UPDATE_FILM_TEST_CONFIG,
    key,
    payload
});

export const changeFormLoadingState = (payload:boolean) => ({
    type: actionTypes.CHANGE_FORM_LOADING_STATE,
    payload
});

export const changeSummaryLoadingState = (payload:boolean) => ({
    type: actionTypes.CHANGE_SUMMARY_LOADING_STATE,
    payload
});

export const changeCloneLoadingState = (payload:boolean) => ({
    type: actionTypes.CHANGE_CLONE_TEST_LOADING_STATE,
    payload
});

export const setHandTestSummaries = (payload:HandTestSummary[]) => ({
    type: actionTypes.SET_HAND_TEST_SUMMARIES,
    payload
});

export const setCurrentHandTest = (payload:any) => ({
    type: actionTypes.SET_HAND_TEST,
    payload
})

export const removeCurrentHandFilmTest = (payload:any = {}) => ({
    type: actionTypes.REMOVE_CURRENT_HAND_FILM_TEST,
});

export const changeGeneratePdfState = (payload:boolean) => ({
    type: actionTypes.CHANGE_GET_PDF_LOADING_STATE,
    payload
});

export const setCurrentHandTestSummaryId = (payload:number) => ({
    type: actionTypes.SET_CURRENT_HAND_TEST_BY_ID,
    payload
})

export const setProducts = (payload:Product[]) => ({
    type: actionTypes.SET_PRODUCTS,
    payload
});