/**
 * @rob4lderman
 * mar2020
 *
 */
import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from './Copyright';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    containerRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    copyrightBox: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: theme.palette.text.primary,
        color: 'white',
        width: '100%',
        padding: theme.spacing(2),
    },
    backgroundBlack: {
        position: 'fixed',
        bottom: 0,
        height: '50%',
        width: '100%',
        backgroundColor: theme.palette.text.primary,
        zIndex: -10000,
    },
}));

const SignInContainer:React.FC<any> = (props:any) => {
    const {
        children
    } = props;
    const classes = useStyles();
    return (
        <Container 
            component="main"
            className={classes.containerRoot}
            >
            <Box mt={2} style={{ alignSelf: 'flex-start' }}>
                    <Link href="/">
                        <img 
                            src="https://www.paragonfilms.com/wp-content/uploads/2019/03/paragonfilms-logo.png" 
                            width="300"
                            alt="paragon films"
                        />
                    </Link>
            </Box>
            { children }
            <Box mt={10}></Box>  {/* make room for copyright box*/ }
            <Box className={classes.backgroundBlack}></Box>
            <Box className={classes.copyrightBox}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default SignInContainer;
