/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import {
    FilmInfo,
} from '../state';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import FilmProductInfoForm from './FilmProductInfoForm';

const FilmInfoProductFormList:React.FC<any> = (props:any) => {
    const {
        filmInfoMap,
    } = props;

    const location = useLocation();

    if ( _.isEmpty( filmInfoMap )) {
        return null;
    }

    const isHandTest:boolean = location.pathname.includes('hand');
    
    return (
        <Grid container spacing={2}>
        {
            _.chain( _.values( filmInfoMap ) )
                .sortBy( (filmInfo:FilmInfo) => filmInfo.sequenceNumber )
                .map( (filmInfo:FilmInfo) => (
                    <Grid item xs={12} sm={4} key={filmInfo.strId}>
                        <FilmProductInfoForm
                            key={ filmInfo.strId }
                            filmInfoStrId={ filmInfo.strId }
                            isHandTest={ isHandTest }
                        />
                    </Grid>
                ))
                .value()
        }
        </Grid>
    );
};

export default FilmInfoProductFormList;
