import React from 'react';
import TextField from '../../material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

interface Props {
    groups: string[];
    value: any;
    onChange: (event:any) => any;
};

const GroupAutoComplete:React.FC<Props> = (props:Props) => {
    const {
        groups,
        value,
        onChange,
    } = props;

    return (
        <Autocomplete
            id="productGroup"
            selectOnFocus
            forcePopupIcon={true}
            options={groups}
            getOptionLabel={option => option}
            style={{ width: '100%'}}
            onInputChange={
                (event:any, value:string, reason:string) => {
                    onChange( { target: { value } } )
                }
            }
            renderInput={
                (params) => {
                    return (
                        <TextField 
                            {..._.merge( params, { inputProps: { value } } ) }
                            name="productGroup"
                            label="Group" 
                            variant="outlined" 
                            fullWidth 
                            value={ value }
                            onBlur={ onChange }
                        />
                    )
                }
            }
        />
    )
};

export default GroupAutoComplete;