/**
 * @rob4lderman
 * feb2020
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { 
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import {
    LoggerFactory,
    sf,
    misc,
} from '../utils';
import _ from 'lodash';
import { 
    useFilmInfo 
} from '../state';

const logger = LoggerFactory( 'FilmConfigurationForm' );

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(2),
    },
}));

interface Props {
    filmInfoStrId: string;
    isHandTest: boolean;
};

const FilmConfigurationForm:React.FC<Props> = (props:Props) => {
    const {
        filmInfoStrId,
        isHandTest,
    } = props;

    const [ _filmInfo, setFilmInfo ] = useFilmInfo( filmInfoStrId );
    const classes = useStyles();

    logger.log( 'render', { _filmInfo, props });

    if ( !!! _filmInfo ) {
        return null;
    }

    return (
        <Box>
            <Typography className={classes.heading} component="h6" variant="h6">
                Film { _filmInfo.sequenceNumber }
            </Typography>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            label="Revolutions"
                            name="revolutions"
                            type="number"
                            value={ misc.numberToString( _filmInfo.revolutions ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'revolutions' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Top Wraps"
                            name="topWrap"
                            type="number"
                            value={ misc.numberToString( _filmInfo.topWrap ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'topWrap' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Bottom Wraps"
                            name="bottomWrap"
                            type="number"
                            value={ misc.numberToString( _filmInfo.bottomWrap ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'bottomWrap' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    { !isHandTest &&
                        <Grid item xs={12} >
                            <TextField
                                label="Pre Stretch Percent"
                                name="preStretchPercent"
                                type="number"
                                value={ misc.numberToString( _filmInfo.preStretchPercent ) }
                                onChange={ 
                                    _.flow( 
                                        sf.lens( 'target.value' ).get,
                                        _.toNumber,
                                        sf.lens( 'preStretchPercent' ).extend( _filmInfo ),
                                        sf.tap( setFilmInfo ),
                                    )
                                }
                            />
                        </Grid>
                    }
                    { !isHandTest &&
                        <Grid item xs={12} >
                            <TextField
                                label="Force Setting"
                                name="forceSetting"
                                type="number"
                                value={ _filmInfo.forceSetting }
                                onChange={ 
                                    _.flow( 
                                        sf.lens( 'target.value' ).get,
                                        sf.lens( 'forceSetting' ).extend( _filmInfo ),
                                        sf.tap( setFilmInfo ),
                                    )
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </form>
        </Box>
    );
};


export default FilmConfigurationForm ;