import {
    getHandTestSummariesThunk,
    removeCurrentHandFilmTest
} from "../actions";

import {
    stateSummaries,
    stateLoadingSummary,
    stateCurrentModel,
    stateProducts
} from "../models";

export const withHandTestFormState = (state:any) => ({
    testSummaries: state.model.handTest[stateSummaries],
    isLoadingTestSummaries: state.model.handTest.isLoading[stateLoadingSummary],
    currentHandTestAnalysys: state.model.handTest[stateCurrentModel],
    currentUser: state.model.userProfile,
    handProducts: state.model.handTest[stateProducts]
});

export const withHandTestSelectors = (dispatch:any) => ({
    getHandTestSummaries: () => dispatch( getHandTestSummariesThunk ),
    removeHandFilm: () => dispatch(removeCurrentHandFilmTest())
});
