
import React from 'react';
import TextField from '../../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


import { connect } from 'react-redux';
import {
    CustomerInfo,
    stateCurrentModel,
    stateCustomerInfo
} from '../../state/handTest/models';
import { updateCustomerInfoField } from '../../state/handTest/actions';
import { getValidPhoneNumber } from '../../utils/misc';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%'
    },
}));

interface Props {
    customerInfo:CustomerInfo,
    updateCustomerInfoField:any
};

const FormCustomerInfo:React.FC<Props> = (props:Props) => {
    const classes = useStyles();
    const targetState = props[stateCustomerInfo];
    const updateState = props.updateCustomerInfoField;

    return (
        <Box width={1}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Company Name"
                                    value={ targetState.name }
                                    onChange={ event => updateState('name', event.target.value) }   
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="contactName"
                                    label="Contact Name"
                                    value={ targetState.contactName }
                                    onChange={ event => updateState('contactName', event.target.value) }   
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="d365AccountNumber"
                                    label="D365 Account #"
                                    value={ targetState.d365AccountNumber }
                                    onChange={ event => updateState('d365AccountNumber', event.target.value) }   
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Company Location"
                                    name="location"
                                    value={ targetState.location }
                                    onChange={ event => updateState('location', event.target.value) }  
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="contactEmail"
                                    label="Email"
                                    value={ targetState.contactEmail }
                                    onChange={ event => updateState('contactEmail', event.target.value) }  
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="distributor"
                                    label="Distributor"
                                    value={ targetState.distributor }
                                    onChange={ event => updateState('distributor', event.target.value) }  
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="contactPhone"
                                    label="Phone"
                                    type="tel"
                                    value={ targetState.contactPhone }
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                    onChange={ event => updateState(
                                        'contactPhone',
                                        getValidPhoneNumber( targetState.contactPhone)(event.target.value)
                                    ) }  
                                />
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
            </form>
        </Box>
    );
};

const mapStateToProps = (state, ownProps?) => ({
    [stateCustomerInfo]: state.model?.handTest?.[stateCurrentModel]?.[stateCustomerInfo]
})

export default connect(
    mapStateToProps,
    {
        updateCustomerInfoField
    }
)(FormCustomerInfo);