/**
 * @rob4lderman
 * feb2020
 * 
 * 
 */
import React from 'react';
import { 
    createStyles,
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import {
    LoggerFactory,
    sf,
} from '../utils';
import _ from 'lodash';
import {
    withFormOptionsState,
    connect,
} from '../state';
import { SalesRep } from '../services/paragon';
const logger = LoggerFactory( 'SalesRepSelect' );

const useStyles = makeStyles((theme: Theme) => ({
    ...createStyles({
      formControl: {
        // margin: theme.spacing(1),
        // minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    }),
}));

interface Props {
    salesReps: SalesRep[];
    value: any;
    error?: boolean;
    helperText?: string;
    onChange: (salesRep:SalesRep) => any;
};

const SalesRepSelect:React.FC<Props> = (props:Props) => {
    const {
        salesReps,
        value,
        error = false,
        helperText = '',
        onChange,
    } = props;
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);
    const classes = useStyles();
    logger.log( 'render', { props });
    return (
        <FormControl 
            variant="outlined" 
            className={classes.formControl}
            error={error}
            fullWidth
            >
            <InputLabel ref={inputLabel} htmlFor="salesRep">
                Sales Rep *
            </InputLabel>
            <Select
                native
                value={ value }
                onChange={ 
                    _.flow( 
                        sf.lens( 'target.value' ).get,
                        // -rx- _.toNumber,
                        (salesRepName:string) => _.find( salesReps, (salesRep:SalesRep) => salesRep.name === salesRepName ),
                        onChange,
                    )
                }
                labelWidth={labelWidth}
                inputProps={{
                    name: 'salesRep',
                    id: 'salesRep',
                }}
                >
                <option 
                    key={ "" }
                    value={""}>
                </option>
                {
                    _.map( 
                        salesReps, 
                        (salesRep:SalesRep) => (
                            <option 
                                key={ salesRep.name }
                                value={ salesRep.name }
                                >
                                { salesRep.name }
                            </option>
                        )
                    )
                }
            </Select>
            {
                error == true &&
                <FormHelperText>{helperText}</FormHelperText>
            }
        </FormControl>
    )
};

export default connect(
    withFormOptionsState,
)( SalesRepSelect );