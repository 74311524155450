/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import ExpansionPanel from '../material/ExpansionPanel';
import CustomerInfoForm from './CustomerInfoForm';
import GeneralInfoForm from './GeneralInfoForm';
import EquipmentInfoForm from './EquipmentInfoForm';
import PalletInfoForm from './PalletInfoForm';
import Box from '@material-ui/core/Box';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    backgroundGrey: {
        backgroundColor: '#e5e5e5',
    },
}));

const useExpansionPanelStyles = makeStyles((theme: Theme) => ({
    root: {
        '&$expanded': {
            margin: 0,
        }
    },
    expanded: {
        margin: 0,
    },
}));

const GeneralInfoTab:React.FC<any> = (props:any) => {
    const classes = useStyles();
    const expansionPanelClasses = useExpansionPanelStyles();
    return (
        <Box>
            <ExpansionPanel 
                title="Test Information" 
                defaultExpanded={true}
                classes={expansionPanelClasses}
                >
                <GeneralInfoForm />
            </ExpansionPanel>
            <ExpansionPanel 
                title="Customer Information" 
                className={classes.backgroundGrey}
                classes={expansionPanelClasses}
                >
                <CustomerInfoForm />
            </ExpansionPanel>
            {/* 
            <ExpansionPanel title="Contacts" >
                <ContactsPanel />
            </ExpansionPanel>
            */}
            <ExpansionPanel 
                title="Pallet Information" 
                classes={expansionPanelClasses}
                >
                <PalletInfoForm />
            </ExpansionPanel>
            <ExpansionPanel 
                title="Equipment Information" 
                className={classes.backgroundGrey}
                classes={expansionPanelClasses}
                >
                <EquipmentInfoForm />
            </ExpansionPanel>
        </Box>
    );
};

export default GeneralInfoTab;
