import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    CatAnalysis,
    selectUserProfile,
    useReduxState
} from "../..";
import { DraftTestSummary, UserProfile } from "../../../types";
import {
    generatePdfThunk,
    getHandTestThunk,
    saveHandTestThunk,
    SET_HAND_TEST_SUMMARIES_FILTER,
    SET_HAND_TEST_SUMMARIES_SEARCH_TEXT,
    setCurrentHandTestSummaryId,
    cloneHandTestThunk
} from "../actions";
import {
    stateFilterTestSummaries,
    stateLoadingForm,
    stateLoadingGetForm,
    stateSummariesSearchText,
    stateLoadingGeneratePdf
} from "../models";
import { HandTestAnalysis } from '../models/types';
import { stateCurrentTestSummaryId, stateSummaries, stateLoadingClone } from '../models/state';

export const useSaveHandTest = () => {
    const isLoadingSaveTest:boolean = useSelector<any,boolean>(
        (state:any): boolean => state?.model?.handTest?.isLoading[stateLoadingForm] || false
    );

    const dispatch = useDispatch();
    
    return {
        saveHandTest: (draftId: number, handTestAnalysis:HandTestAnalysis, catAnalysis:CatAnalysis): Promise<DraftTestSummary> => dispatch(
            saveHandTestThunk( draftId, handTestAnalysis, catAnalysis )
        ) as unknown as Promise<DraftTestSummary>,
        isLoadingSaveTest,
    };
};

export const useGetHandTest = () => {
    const isLoadingGetState:boolean = useSelector<any,boolean>(
        (state:any): boolean => state.model.handTest.isLoading[stateLoadingGetForm]
    );

    const dispatch = useDispatch();

    return {
        getHandTest: (testId: number): Promise<CatAnalysis> => dispatch( getHandTestThunk(testId) ) as unknown as Promise<CatAnalysis>,
        isLoadingGetState,
    };
};

export const useFilterTest = (): [ boolean, (a:boolean) => any ] => {
    return useReduxState( 
        (state:any): boolean => state.model.handTest[stateFilterTestSummaries],
        SET_HAND_TEST_SUMMARIES_FILTER,
    );
};

export const useSearchText = (): [ string, (a:string) => any ] => {
    return useReduxState( 
        (state:any): string => state.model.handTest[stateSummariesSearchText],
        SET_HAND_TEST_SUMMARIES_SEARCH_TEXT,
    );
};

export const useGeneratePdf = () => {
    const isLoadingGeneratePdf:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.handTest.isLoading[stateLoadingGeneratePdf] );
    const dispatch = useDispatch();
    return {
        generatePdf: (draftId: number, handTestAnalysis:HandTestAnalysis, catAnalysis:CatAnalysis): Promise<any> => dispatch(
            generatePdfThunk( draftId, handTestAnalysis, catAnalysis ) 
        ) as unknown as Promise<any>,
        isLoadingGeneratePdf,
    };
};

export const useSetCurrentHandTestSummary = () => {
    const userProfile:UserProfile = useSelector( selectUserProfile );

    const currentState = useSelector(
        (state:any): any => ({
            summaries: state.model.handTest[stateSummaries],
            currentTestId: state.model.handTest[stateCurrentTestSummaryId]
        })
    );
    
    const canEditCurrentHandTest:boolean = currentState.summaries.find(x => x.id === currentState.currentTestId && x.createdBy.toLocaleLowerCase() === userProfile.email.toLocaleLowerCase());

    const dispatch = useDispatch();

    return {
        canEditCurrentHandTest,
        setCurrentHandTestSumamaryById: (id:number) => dispatch( setCurrentHandTestSummaryId(id) )
    }
}

export const useCloneHandTest = () => {
    const isLoadingCloneTest:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.handTest?.isLoading[stateLoadingClone] || false );

    const dispatch = useDispatch();

    return {
        cloneTest: (draftId: number ): Promise<CatAnalysis> => dispatch( cloneHandTestThunk( draftId ) ) as unknown as Promise<CatAnalysis>,
        isLoadingCloneTest,
    };
};
