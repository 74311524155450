/**
 * @rob4lderman
 * mar2020
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';

const SubTitle:React.FC<any> = (props:any) => {
    const {
        children,
        ...rprops
    } = props;
    return (
        <Typography 
            component="h6" 
            variant="subtitle2"
            {...rprops}
            >
            { children }
        </Typography>
    );
};

export default SubTitle;