import _ from "lodash";
import { DraftTestSummary } from "../../../services/paragon";
import { sf } from "../../../utils";
import {
    HandTestAnalysis,
    HandTestSummary,
    mapGetHandTestPayload,
    mapHandTestAnalysisPayload
} from "../models";
import {
    changeCloneLoadingState,
    changeFormLoadingState,
    changeGeneratePdfState,
    changeSummaryLoadingState,
    setCurrentHandTest,
    setHandTestSummaries,
    setProducts
} from "./actions";
import {
    CatAnalysis,
    TestPayload
} from '../../types';
import * as paragon from '../../../services/paragon';
import * as catModel from '../../catModel';
import { ActionTypes } from "../..";

export const saveHandTestThunk =
    (draftId: number, handTestAnalysis:HandTestAnalysis, catAnalysis:CatAnalysis) =>
    (dispatch:any, getState:any) : Promise<DraftTestSummary> => {

    dispatch( changeFormLoadingState(true) );

    return Promise.resolve( {
            handTestAnalysis,
            catAnalysis
        } )
        .then( mapHandTestAnalysisPayload ) 
        .then( _.partial( paragon.saveTest, draftId ) )
        .then( sf.tap_catch( () => dispatch( getHandTestSummariesThunk )))
        .then( sf.tap( () => dispatch( changeFormLoadingState(false) ) ) )
        .catch( sf.tap_throw( () => dispatch( changeFormLoadingState(false) ) ) );
};

export const getHandTestSummariesThunk = (dispatch:any, getState:any): Promise<any> => {
    dispatch( changeSummaryLoadingState(true) );

    return paragon.getTestSummaries(2)
        .then( sf.tap( (values:HandTestSummary[]) => dispatch( setHandTestSummaries(values) ) ) )
        .then( sf.tap( () => dispatch( changeSummaryLoadingState(false) ) ) )
        .catch( sf.tap_throw( () => dispatch( changeSummaryLoadingState(false) ) ) );
};

export const getHandTestThunk = (testId:number) => async (dispatch:any, getState:any) => {
    dispatch( changeFormLoadingState(true) );

    try {
        const response:TestPayload = await paragon.getTest(testId);
        const mapCatAnalysis = catModel.mapTestPayloadToCatAnalysis(response);
        const mapHandTest = mapGetHandTestPayload(response);

        dispatch({ type: ActionTypes.setCurrentCatAnalysis, payload: mapCatAnalysis });
        dispatch( setCurrentHandTest(mapHandTest) );
    } catch (error) {
        console.error(error); // TODO: remove when everything passes the tests
    } finally {
        dispatch( changeFormLoadingState(false) );
    }
};

export const generatePdfThunk = (draftId: number, handTestAnalysis:HandTestAnalysis, catAnalysis:CatAnalysis) => (dispatch:any, getState:any) : Promise<any> => {
    dispatch( changeGeneratePdfState(true) );

    return Promise.resolve( {
            handTestAnalysis,
            catAnalysis
        } )
        .then( mapHandTestAnalysisPayload ) 
        .then( _.partial( paragon.generatePdf, draftId ) )
        .then( sf.tap( () => dispatch( changeGeneratePdfState(false) ) ) )
        .catch( sf.tap_throw( () => dispatch( changeGeneratePdfState(false) ) ) );
};

export const getHandProducts = (dispatch:any): Promise<any> => paragon.getProducts( true )
        .then( (products:paragon.Product[]) => dispatch( setProducts( products ) ) );

export const cloneHandTestThunk = (draftId: number) => async(dispatch:any, getState:any) => {
    try {
        dispatch( changeCloneLoadingState( true ) );

        const response:TestPayload = await paragon.cloneTest( draftId );

        const mapCatAnalysis = catModel.mapTestPayloadToCatAnalysis(response);
        const mapHandTest = mapGetHandTestPayload(response);

        mapCatAnalysis.strId = 'working';

        dispatch ( setCurrentHandTest( mapHandTest ) );
        dispatch({ type: ActionTypes.setCurrentCatAnalysis, payload: mapCatAnalysis })
    } catch (error) {
    } finally {
        dispatch( changeCloneLoadingState( false) );
    }
};