/**
 * @rob4lderman
 * feb2020
 * 
 * TODO: responsiveness
 * 
 * Copied lovingly from: 
 * https://material-ui.com/getting-started/templates/#react-templates
 * https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/sign-up
 * 
 */
import React from 'react';
import {
    useState,
} from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    RegisterRequest
} from '../types';
import {
    LoggerFactory,
    misc,
    sf,
} from '../utils';
import SalesRepSelect from './SalesRepSelect';
import _ from 'lodash';
import Button from '../material/Button';
import {
    useSignInEmail,
    useGetSalesReps,
} from '../state';
const logger = LoggerFactory( 'SignUpForm' );


const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
}));

interface Props {
    onSubmit: (event:any, data:RegisterRequest) => Promise<any>;
    isLoading: boolean;
};

 
const SignUpForm:React.FC<Props> = (props:Props) => {
    const {
        onSubmit,
        isLoading,
    } = props;
    const [ email, setEmail ] = useSignInEmail();
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ axSalesGroup, setAxSalesGroup ] = useState('');
    const [ axSalesRepName, setAxSalesRepName ] = useState('');
    const [ street, setStreet ] = useState('');
    const [ city, setCity ] = useState('');
    const [ state, setState ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');
    const [ country, setCountry ] = useState('');

    const [ emailError, setEmailError ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ confirmPasswordError, setConfirmPasswordError ] = useState('');
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastNameError, setLastNameError ] = useState('');
    const [ companyError, setCompanyError ] = useState('');
    const [ axSalesGroupError, setAxSalesGroupError ] = useState('');
    const [ streetError, setStreetError ] = useState('');
    const [ cityError, setCityError ] = useState('');
    const [ stateError, setStateError ] = useState('');
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ countryError, setCountryError ] = useState('');
    const { getSalesReps, salesReps } = useGetSalesReps();

    React.useEffect(
        () => {
            getSalesReps()
        },
        []
    )
    logger.log('render: salesReps', salesReps);

    const classes = useStyles();
    const validate = (data:RegisterRequest) : RegisterRequest => {
        logger.log( "SignUpForm.validate", { data } );
        const validated = [
            misc.validateNotEmpty( data.FirstName, _.partial( setFirstNameError, 'Required' ) ),
            misc.validateNotEmpty( data.LastName, _.partial( setLastNameError, 'Required' ) ),
            misc.validateNotEmpty( data.Email, _.partial( setEmailError, 'Required' ) ),
            misc.validateNotEmpty( data.Password, _.partial( setPasswordError, 'Required' ) ),
            misc.validateNotEmpty( data.ConfirmPassword, _.partial( setConfirmPasswordError, 'Required' ) ),
            misc.validateTrue( data.ConfirmPassword === data.Password, _.partial( setConfirmPasswordError, 'Password does not match' ) ),
            misc.validateNotEmpty( data.Company, _.partial( setCompanyError, 'Required' ) ),
            misc.validateNotEmpty( data.Street, _.partial( setStreetError, 'Required' ) ),
            misc.validateNotEmpty( data.City, _.partial( setCityError, 'Required' ) ),
            misc.validateNotEmpty( data.State, _.partial( setStateError, 'Required' ) ),
            misc.validateNotEmpty( data.PostalCode, _.partial( setPostalCodeError, 'Required' ) ),
            misc.validateNotEmpty( data.AxSalesGroup, _.partial( setAxSalesGroupError, 'Required' ) ),
            // misc.validateNotEmpty( data.Country, _.partial( setCountryError, 'Required' ) ),
        ];
        misc.assertAllTrue( validated )
        return data;
    };

    const _onSubmit = (event:any) => {
        event.preventDefault();
        return Promise.resolve({ 
                Email: email, 
                Password: password,
                ConfirmPassword: confirmPassword,
                FirstName: firstName,
                LastName: lastName,
                Company: company,
                Street: street,
                City: city,
                State: state,
                PostalCode: postalCode,
                Country: country,
                AxSalesGroup: axSalesGroup,
                UserType: 3,
            })
            .then( validate )
            .then( _.partial( onSubmit, event ) )
            .catch( logger.logErrorFn('onSubmit') )
            ;
    }

    return (
        <form className={classes.form} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        label="First Name"
                        value={firstName}
                        {...misc.getTextFieldError( firstNameError )}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 50 ),
                                setFirstName,
                                _.partial( setFirstNameError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        {...misc.getTextFieldError( lastNameError )}
                        autoComplete="family-name"
                        value={lastName}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 50 ),
                                setLastName,
                                _.partial( setLastNameError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        name="company"
                        label="Company"
                        autoComplete="off" // TODO
                        {...misc.getTextFieldError( companyError )}
                        value={company}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 100 ),
                                setCompany,
                                _.partial( setCompanyError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SalesRepSelect
                        salesReps={salesReps}
                        value={ axSalesRepName }
                        {...misc.getTextFieldError( axSalesGroupError )}
                        onChange={ 
                            (salesRep) => {
                                setAxSalesGroup(salesRep.salesGroup)
                                setAxSalesRepName(salesRep.name)
                            }
                        }
                    />
                </Grid>
 
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        fullWidth
                        name="street"
                        label="Street Address"
                        {...misc.getTextFieldError( streetError )}
                        autoComplete="street-address" 
                        value={street}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 150 ),
                                setStreet,
                                _.partial( setStreetError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        fullWidth
                        name="city"
                        label="City"
                        {...misc.getTextFieldError( cityError )}
                        autoComplete="address-level2" 
                        value={city}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 50 ),
                                setCity,
                                _.partial( setCityError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        required
                        fullWidth
                        name="state"
                        label="State"
                        {...misc.getTextFieldError( stateError )}
                        autoComplete="address-level1"
                        value={state}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 3 ),
                                setState,
                                _.partial( setStateError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        required
                        fullWidth
                        name="postalCode"
                        label="Zip"
                        {...misc.getTextFieldError( postalCodeError )}
                        autoComplete="postal-code" 
                        value={postalCode}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 10 ),
                                setPostalCode,
                                _.partial( setPostalCodeError, '' )
                            )
                        }
                    />
                </Grid>
                {/* -rx- 
                <Grid item xs={12} sm={1}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="country"
                        label="Country"
                        id="country"
                        {...misc.getTextFieldError( countryError )}
                        autoComplete="country"
                        value={country}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                misc.truncateAt( 3 ),
                                setCountry,
                                _.partial( setCountryError, '' )
                            )
                        }
                    />
                </Grid>
                */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        fullWidth
                        label="Email Address"
                        name="email"
                        type="email"
                        {...misc.getTextFieldError( emailError )}
                        autoComplete="email"
                        value={email}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                setEmail,
                                _.partial( setEmailError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        {...misc.getTextFieldError( passwordError )}
                        autoComplete="current-password"
                        value={password}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                setPassword,
                                _.partial( setPasswordError, '' )
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        autoComplete="off"
                        {...misc.getTextFieldError( confirmPasswordError )}
                        value={confirmPassword}
                        onChange={ 
                            _.flow( 
                                sf.lens( 'target.value' ).get,
                                setConfirmPassword,
                                _.partial( setConfirmPasswordError, '' )
                            )
                        }
                    />
                </Grid>
            </Grid>
            <Box mt={3}>
                <Grid container direction={'row-reverse'} spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type="submit"
                            fullWidth
                            onClick={ _onSubmit }
                            isLoading={isLoading}
                            >
                            REGISTER
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default SignUpForm;