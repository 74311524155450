/**
 * @rob4lderman
 * feb2020
 * 
 */
import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { 
    makeStyles,
    Theme,
 } from '@material-ui/core/styles';
import {
    LoggerFactory,
    sf,
    misc,
} from '../utils';
import _ from 'lodash';
import { 
    useFilmInfo,
} from '../state';
const logger = LoggerFactory( 'FilmTestResultsForm' );

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(2),
    },
}));

interface Props {
    filmInfoStrId: string;
    isHandTest: boolean;
};

const FilmTestResultsForm:React.FC<Props> = (props:Props) => {
    const {
        filmInfoStrId,
        isHandTest
    } = props;
    const [ _filmInfo, setFilmInfo ] = useFilmInfo( filmInfoStrId );
    const classes = useStyles();
    logger.log( 'render', { _filmInfo, props });
    if ( !!! _filmInfo ) {
        return null;
    }
    return (
        <Box>
            <Typography className={classes.heading} component="h6" variant="h6">
                Film { _filmInfo.sequenceNumber }
            </Typography>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            label="Top Containment"
                            name="topContainment"
                            type="number"
                            value={ misc.numberToString( _filmInfo.topContainment ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'topContainment' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Middle Containment"
                            name="middleContainment"
                            type="number"
                            value={ misc.numberToString( _filmInfo.middleContainment ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'middleContainment' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Bottom Containment"
                            name="bottomContainment"
                            type="number"
                            value={ misc.numberToString( _filmInfo.bottomContainment ) }
                            onChange={ 
                                _.flow( 
                                    sf.lens( 'target.value' ).get,
                                    misc.textInputToNumber,
                                    sf.lens( 'bottomContainment' ).extend( _filmInfo ),
                                    sf.tap( setFilmInfo ),
                                )
                            }
                        />
                    </Grid>
                        <Grid item xs={12} >
                            <TextField
                                label="Ounces Used"
                                name="cutWeigh"
                                type="number"
                                value={ misc.numberToString( _filmInfo.cutWeigh ) }
                                onChange={ 
                                    _.flow( 
                                        sf.lens( 'target.value' ).get,
                                        misc.textInputToNumber,
                                        sf.lens( 'cutWeigh' ).extend( _filmInfo ),
                                        sf.tap( setFilmInfo ),
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box>
    );
};


export default FilmTestResultsForm ;