import React from 'react';
import TextField from '../material/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
    sf,
} from '../utils';
import _ from 'lodash';
import {
    useCustomerInfo
} from '../state';
import { getValidPhoneNumber, truncateAt } from '../utils/misc';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
    },
}));

interface Props { };


const CustomerInfoForm: React.FC<Props> = (props: Props) => {
    const [_customerInfo, setCustomerInfo] = useCustomerInfo();
    const classes = useStyles();

    return (
        <Box style={{ width: '100%' }}>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Company Name"
                                    value={_customerInfo.name}
                                    onChange={
                                        _.flow(
                                            sf.lens('target.value').get,
                                            sf.lens('name').extend(_customerInfo),
                                            sf.tap(setCustomerInfo),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="contactName"
                                    label="Contact Name"
                                    value={_customerInfo.contactName}
                                    onChange={
                                        _.flow(
                                            sf.lens('target.value').get,
                                            sf.lens('contactName').extend(_customerInfo),
                                            sf.tap(setCustomerInfo),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="d365AccountNumber"
                                    label="D365 Account #"
                                    value={_customerInfo.d365AccountNumber}
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                    onChange={
                                      _.flow(
                                        sf.lens('target.value').get,
                                        sf.lens('d365AccountNumber').extend(_customerInfo),
                                        sf.tap(setCustomerInfo),
                                    )
                                    }
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Company Location"
                                    name="location"
                                    value={_customerInfo.location}
                                    onChange={
                                        _.flow(
                                            sf.lens('target.value').get,
                                            sf.lens('location').extend(_customerInfo),
                                            sf.tap(setCustomerInfo),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="contactEmail"
                                    label="Email"
                                    value={_customerInfo.contactEmail}
                                    onChange={
                                        _.flow(
                                            sf.lens('target.value').get,
                                            sf.lens('contactEmail').extend(_customerInfo),
                                            sf.tap(setCustomerInfo),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="distributor"
                                    label="Distributor"
                                    value={_customerInfo.distributor}
                                    onChange={
                                        _.flow(
                                            sf.lens('target.value').get,
                                            sf.lens('distributor').extend(_customerInfo),
                                            sf.tap(setCustomerInfo),
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="contactPhone"
                                    label="Phone"
                                    value={_customerInfo.contactPhone}
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                    onChange={
                                        _.flow(
                                            sf.lens('target.value').get,
                                            getValidPhoneNumber( _customerInfo.contactPhone ),
                                            truncateAt( 25 ),
                                            sf.lens('contactPhone').extend(_customerInfo),
                                            sf.tap(setCustomerInfo),
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default CustomerInfoForm;