/**
 * @rob4lderman
 * feb2020
 */

import * as paragon from '../services/paragon';
import moment from 'moment';
import { getDescription } from '../utils/misc';

export interface ModelShape {
    [key:string]: any;
    userProfile: paragon.UserProfile;
    // -rx- authToken?: string;
    parsedAuthToken: ParsedAuthToken;
    testSummaries: paragon.TestSummary[];
    equipmentManufacturers: paragon.Manufacturer[];
    equipmentList: paragon.Equipment[];
    equipmentTypes: paragon.EquipmentType[];
    productManufacturers: paragon.Manufacturer[];
    products: paragon.Product[];
    customerContactTypes: paragon.CustomerContactType[];
    loadProfiles: paragon.LoadProfile[];
    testMethods: paragon.TestMethod[];
    catAnalyses: CatAnalyses;
    salesReps: paragon.SalesRep[];
};

export interface Contact {
    id: number;            // server id
    strId?: string;         // temp id used by frontend when creating a new record
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
    type: paragon.CustomerContactType;
};

export interface PalletInfo {
    palletsPerDay: number;
    daysPerWeek: number;
    palletHeight: number;
    palletLength: number;
    palletWidth: number;
    weightRange: number;
    loadProfile: paragon.LoadProfile;
    hourlyWage: number;
    rollChangeTime: number;
    putAwayTime: number;
};

export interface CustomerInfo {
    id: number;
    name: string;
    location: string;
    distributor: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    d365AccountNumber: string;
};

export interface GeneralInfo {
    name: string;
    testDateTime: Date;
    notes: string;
    includeNotesOnReport: boolean;
    testTypeId: number;
};

export interface EquipmentInfo {
    // -rx- manufacturer: paragon.Manufacturer;
    equipmentManufacturer: string;
    equipmentType: string;
    equipmentModel: string;
    equipmentSerialNumber: string;
    carriageSpeed: string;
    rotationSpeed: string;
    // -rx- forceSetting: string;
    // -rx- topCounter: number;
    // -rx- bottomCounter: number;
    // -rx- largeToothSprocket: number;
    // -rx- smallToothSprocket: number;
    // -rx- dryRoller1: number;
    // -rx- dryRoller2: number;
};

export interface ContactsMap {
    [strId:string]: Contact;
}

export interface FilmInfoMap {
    [strId:string]: FilmInfo;
}

export interface CatAnalysis {
    id: number;         // server id
    strId?: string;     // temp id used by frontend when creating a new record

    createdBy?: string;
    createdDateTime?: string;
    modifiedBy?: string;
    modifiedDateTime?: string;
    testConfigurationId?: number;

    generalInfo: GeneralInfo;
    customerInfo: CustomerInfo;
    contactsMap: ContactsMap;
    palletInfo: PalletInfo;
    equipmentInfo: EquipmentInfo;
    filmInfoMap: FilmInfoMap;
    testMethod: paragon.TestMethod;
};

export interface CatAnalysisMap {
    [strId:string]: CatAnalysis
};

export interface CatAnalyses {
    currentStrId: string;
    catAnalysisMap: CatAnalysisMap;
};

export interface FilmInfo {
    id: number;        // server id
    strId?: string;     // temp id used by frontend when creating a new record
    sequenceNumber: number;

    // film info
    productManufacturer: string;
    productGroup: string;
    productName: string;

    // product
    description: string;
    gauge: number;
    width: number;
    length: number;    // TODO: length?
    rollPrice: number;
    netPolyWeight: number;
    coreWeight: number;
    rollsPerSkid: number;
    pcrContent: number;

    // configuration
    revolutions: number;
    topWrap: number;
    bottomWrap: number;
    preStretchPercent: number;
    forceSetting: string;
    savingsPercent?: number;

    // test results
    topContainment: number;
    middleContainment: number;
    bottomContainment: number;
    cutWeigh: number;
    loadPerRoll?: number;

    // product
    product?: any
};

export const initPalletInfo = (): PalletInfo => ({
    palletsPerDay: null,
    daysPerWeek: null,
    palletHeight: null,
    palletLength: null,
    palletWidth: null,
    weightRange: null,
    hourlyWage: 20.00,
    putAwayTime: 2.50,
    rollChangeTime: 1.50,
    loadProfile: initLoadProfile(),
});

export const initGeneralInfo = (): GeneralInfo => ({
    name: '',
    testDateTime: moment().toDate(),
    notes: '',
    includeNotesOnReport: false,
    testTypeId: 1,
});

export const initCustomerInfo = (): CustomerInfo => ({
    id: null,
    name: '',
    location: '',
    distributor: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    d365AccountNumber: '',
});

export const initEquipmentInfo = (): EquipmentInfo => ({
    // -rx- manufacturer: initManufacturer(),
    equipmentManufacturer: '',
    equipmentType: '',
    equipmentModel: '',
    equipmentSerialNumber: '',
    rotationSpeed: '',
    carriageSpeed: '',
    // -rx- forceSetting: '',
    // -rx- topCounter: 0,
    // -rx- bottomCounter: 0,
    // -rx- largeToothSprocket: 0,
    // -rx- smallToothSprocket: 0,
    // -rx- dryRoller1: 0,
    // -rx- dryRoller2: 0,
});

export const initLoadProfile = (): paragon.LoadProfile => ({
    id: 0,
    name: '',
});

export const initManufacturer = ():paragon.Manufacturer => ({
    id: 0,
    name: '',
});

export const initEquipmentType = ():paragon.Manufacturer => ({
    id: 0,
    name: '',
});

export const initTestMethod = ():paragon.TestMethod => ({
    id: 0,
    name: '',
});

export const initProduct = ():paragon.Product => ({
    id: 0,
    name: '',
    group: '',
    description: '',
    manufacturer: initManufacturer(),
    gauge: 0,
    micron: 0,
    width: 0,
    widthMetric: '',
    length: 0,
    lengthMetric: '',
    netPolyWeight: 0,
    rollsPerSkid: 0,
    coreWeight: 0,
    pcrContent: 0,
})

export const initFilmInfo = (sequenceNumber:number): FilmInfo => ({
    id: null,
    strId: `id-${sequenceNumber}`,
    sequenceNumber,

    // product
    productManufacturer: sequenceNumber <= 1 ? '' : 'Paragon Films',
    productGroup: '',
    productName: '',

    description: getDescription(sequenceNumber),
    gauge: null,
    width: null,
    length: null,
    rollPrice: null,
    netPolyWeight: null,

    // configuration
    revolutions: null,
    topWrap: null,
    bottomWrap: null,
    preStretchPercent: null,
    forceSetting: '',
    rollsPerSkid: 0,
    coreWeight: 0,
    pcrContent: 0,

    // test results
    topContainment: null,
    middleContainment: null,
    bottomContainment: null,
    cutWeigh: null,
});

export const initFilmInfoMap = ():FilmInfoMap => {
    const film1 = initFilmInfo(1);
    const film2 = initFilmInfo(2);
    const film3 = initFilmInfo(3);
    return {
        [film1.strId]: film1,
        [film2.strId]: film2,
        [film3.strId]: film3,
    };
};

export const initContact = (strId:string): Contact => ({
    id: null,
    strId,
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phone: '',
    type: {
        id: 0,
        name: '',
    },
})

export const initCatAnalysis = (strId:string): CatAnalysis => ({
    id: null,
    strId,
    generalInfo: initGeneralInfo(),
    customerInfo: initCustomerInfo(),
    contactsMap: {},
    filmInfoMap: initFilmInfoMap(),
    palletInfo: initPalletInfo(),
    equipmentInfo: initEquipmentInfo(),
    testMethod: initTestMethod()
})


//
// Paragon API
//

export interface TestConfiguration {
    id: number;
    testMethod: paragon.TestMethod;
    loadProfile: paragon.LoadProfile;
    equipmentModel: string;
    equipmentType: string;
    equipmentManufacturer: string;
    palletsPerDay: number;
    daysPerWeek: number;
    palletLength: number;
    palletWidth: number;
    palletHeight: number;
    weightRange: number;
    equipmentSerialNumber: string;
    carriageSpeed: string;
    rotationSpeed: string;
    hourlyWage: number;
    putAwayTime: number;
    rollChangeTime: number;
};

export interface TestResult {
    id: number;
    sequenceNumber: number;
    productManufacturer: string;
    productGroup: string;
    productName: string;
    description: string;
    gauge: number;
    width: number;
    length: number;
    netPolyWeight: number;
    cutWeigh: number;
    topContainment: number;
    middleContainment: number;
    bottomContainment: number;
    rollPrice: number;
    revolutions: number;
    topWrap: number;
    bottomWrap: number;
    preStretchPercent: number;
    forceSetting: string;
    rollsPerSkid: number;
    coreWeight: number;
    pcrContent: number;
}

export interface TestCustomer {
    id: number;
    name: string;
    distributor: string;
    location: string;
    contacts: Contact[];
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    d365AccountNumber: string;
};

export interface TestPayload {
    id: number;
    name: string;
    testTypeId: number;
    testDateTime: string;
    notes: string;
    includeNotesOnReport: boolean;
    createdBy?: string;
    createdDateTime?: string;
    modifiedBy?: string;
    modifiedDateTime?: string;
    customer: TestCustomer;
    testConfiguration: TestConfiguration;
    testResults: TestResult[];
};

//
// SaveTest payload:
//
// {
//     "id":3978,
//     "name":"CASE STUDY - PARAGON 50 - BERRY 70",
//     "testDateTime":"2019-12-12T12:45:05.69",
//     "notes":"",
//     "createdBy":"Kenny.Mullican@ParagonFilms.com",
//     "createdDateTime":"2020-01-20T15:04:06.233",
//     "modifiedBy":"ron@freeformagency.com",
//     "modifiedDateTime":"2020-01-20T21:54:49.8792434+00:00",
//     "customer":{
//         "id":3980,
//         "name":"CASE STUDY - Paragon 50 - Berry 70",
//         "distributor":"",
//         "location":"Oklahoma",
//         "contacts":[
//             {
//                 "id":4224,
//                 "type":{
//                     "id":3,
//                     "name":"Paragon"
//                 },
//                 "title":"",
//                 "firstName":"Jerad",
//                 "lastName":"Dowler",
//                 "phone":"",
//                 "email":"Jerad.dowler@paragonfilms.com"
//             }
//         ]
//     },
//     "testConfiguration": {
//         "id":3977,
//         "testMethod":{
//             "id":3,
//             "name":"Torsion Tool"
//         },
//         "loadProfile":{
//             "id":2,
//             "name":"B-Load"
//         },
//         "equipmentModel":"Q-300",
//         "equipmentType":"Turntable",
//         "equipmentManufacturer":"Lantech",
//         "palletsPerDay":385,
//         "daysPerWeek":5,
//         "palletLength":41,
//         "palletWidth":39,
//         "palletHeight":48,
//         "weightRange":"650",
//         "equipmentSerialNumber":"",
//         "carriageSpeed":"7",
//         "rotationSpeed":5
//     },
//     "testResults": [
//         {
//             "id":6382,
//             "sequenceNumber":1,
//             "productManufacturer":"Berry",
//             "productGroup":"MaxTech",
//             "productName":"AMP22063",
//             "gauge":70,
//             "width":20,
//             "length":6000,
//             "netPolyWeight":33.6,
//             "cutWeigh":4.8,
//             "topContainment":5,
//             "middleContainment":4.6,
//             "bottomContainment":5.5,
//             "rollPrice":58,
//             "revolutions":11,
//             "topWrap":3,
//             "bottomWrap":3,
//             "preStretchPercent":200,
//             "forceSetting":"3"
//         },
//         {
//             "id":6383,
//             "sequenceNumber":2,
//             "productManufacturer":"Paragon Films",
//             "productGroup":"Nexus",
//             "productName":"NX.127500",
//             "gauge":50,
//             "width":19.7,
//             "length":9000,
//             "netPolyWeight":35.46,
//             "cutWeigh":3.6,
//             "topContainment":4.1,
//             "middleContainment":3.3,
//             "bottomContainment":4.6,
//             "rollPrice":62.64,
//             "revolutions":11,
//             "topWrap":3,
//             "bottomWrap":3,
//             "preStretchPercent":200,
//             "forceSetting":"3"
//         },
//         {
//             "id":6384,
//             "sequenceNumber":3,
//             "productManufacturer":"Paragon Films",
//             "productGroup":"Nexus",
//             "productName":"NX.127500",
//             "gauge":50,
//             "width":19.7,
//             "length":9000,
//             "netPolyWeight":35.46,
//             "cutWeigh":3.3,
//             "topContainment":6.2,
//             "middleContainment":4.2,
//             "bottomContainment":6.5,
//             "rollPrice":62.64,
//             "revolutions":11,
//             "topWrap":3,
//             "bottomWrap":3,
//             "preStretchPercent":200,
//             "forceSetting":"7"
//         }
//     ]
// }
// 

export interface AlertData {
    title?: string;
    text?: string;
};

export interface ParsedAuthToken {
    'AspNet.Identity.SecurityStamp': string;
    aud: string;
    axSalesGroup: string;
    city: string;
    company: string[];
    country: string;
    exp: number;
    firstName: string;
    iat: number;
    iss: string;
    lastName: string;
    nameid: string;
    nbf: number;
    postalCode: string;
    role: string;   // TODO: string[] ?
    street: string;
    unique_name: string;
}