/**
 * @rob4lderman
 * feb2020
 */
import React from 'react';
import TitleH6 from '../material/TitleH6';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    useAlertWindow,
} from '../state';
import _ from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    pre: {
        whiteSpace: 'pre-wrap',
    },
    bold: {
        fontWeight: 800,
    },
}));

const useDialogStyles = makeStyles((theme: Theme) => ({
    paper: {
        borderRadius: 2,
        borderWidth: 1,
        borderColor: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            minWidth: 300,
        },
    },
}));

const useButtonStyles = makeStyles((theme: Theme) => ({
    label: {
        fontWeight: 800,
    },
}));



interface Props {};

const AlertWindow:React.FC<Props> = (props:Props) => {
    const { 
        alertData,
        closeAlertWindow,
    } = useAlertWindow();
    const isVisible = !!! _.isEmpty( alertData.title )
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const dialogClasses = useDialogStyles();
    if ( !!! isVisible ) {
        return null;
    }
    return (
        <div>
            <Dialog
                open={isVisible}
                onClose={closeAlertWindow}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={dialogClasses}
                scroll='body'
                >
                <DialogTitle id="alert-dialog-title">
                    <TitleH6 style={{ textTransform: 'uppercase' }}>
                        { alertData.title }
                    </TitleH6>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.pre} id="alert-dialog-description">
                        { alertData.text }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button classes={buttonClasses} onClick={closeAlertWindow} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AlertWindow;