export const timeFormat:string = 'MM/DD/YYYY' as const;

export const routeMachineTests:string = 'machine-tests' as const;
export const routeHandTests:string = 'hand-tests' as const;
export const routeEditMachine:string = 'edit-machine-test' as const;
export const routeEditHand:string = 'edit-hand-test' as const;

export const REGEX_PHONE_NUMBER:RegExp = /^[+]?[0-9]*$/;

export const FIELD_DESCRIPTION_BY_SEQUENCE:string[] = [
    'Current Film',
    'Suggested Film',
    'Alternate Film'
];
