/**
 * @rob4lderman
 * jul2019
 *
 */


import * as sf from './sf';
import { LoggerFactory } from './logger';
import _ from 'lodash';
import { Promise } from 'bluebird';
Promise.config({
    cancellation: true,
})
const logger = LoggerFactory('fetch');


// -rx- /**
// -rx-  * @param file - as returned by <input type="file">
// -rx-  * @param body - other form parms
// -rx-  *
// -rx-  * @return FormData
// -rx-  */
// -rx- const createFormData = (file, body) => {
// -rx-     const data = new FormData();
// -rx-     data.append('photo', file);
// -rx- 
// -rx-     Object.keys(body).forEach(key => {
// -rx-         data.append(key, body[key]);
// -rx-     });
// -rx- 
// -rx-     return data;
// -rx- };
// -rx- 
// -rx- /**
// -rx-  * @return promise
// -rx-  */
// -rx- const postFile = (url, file, data) => {
// -rx-     return fetch( buildApiUrl( url ), {
// -rx-         method: "POST",
// -rx-         body: createFormData( file, data ),
// -rx-         headers: {
// -rx-             // -rx- 'x-access-token': _.result( store.getState(), 'model.jwt', '' ),
// -rx-         },
// -rx-     })
// -rx-         .then( throwIfNotOk )
// -rx-         .then( res => res.json() )
// -rx-         .then( sf.tap( res => logger.log( { url, res } ) ) )
// -rx-         .then( throwGraphQLError )
// -rx-         .then( res => res.data )
// -rx-         .catch( sf.thru_wait_throw( parseError ) )
// -rx-         .catch( sf.tap_wait_throw( logError(url) ) )
// -rx-         // .catch( sf.tap_wait_throw( alertError ) )
// -rx-         ;
// -rx- };


/**
 * @return res
 * @throw res (if error)
 */
const throwIfNotOk = (res) => {
    if ( !!! res.ok || res.status === 500 || !!! res.json) {
        throw res;
    } else {
        return res;
    }
};

/**
 * @return true if content-type application/json
 */
const isJsonResponse = (res) => {
    const retMe = res.headers && _.includes( _.toLower( res.headers.get('content-type') ),  'application/json' );
    // logger.log( "isJsonResponse", { retMe, headers: res.headers, contentType: res.headers.get('content-type') } );
    return retMe;
};

/**
 * @return true if content-type application/json
 */
const isPdfResponse = (res) => {
    const retMe = res.headers && _.includes( _.toLower( res.headers.get('content-type') ),  'application/pdf' );
    // logger.log( "isPdfResponse", { retMe, headers: res.headers, contentType: res.headers.get('content-type') } );
    return retMe;
};


/**
 * Log error.
 * @return void
 */
const logError = (val) => (err) => logger.error( 'ERROR', { err, val } );

/**
 * @param res 
 *          | fetch() error response 
 *          | first graphql error in the response
 * 
 * @return parsed error { code: xx, message: yy }
 */
const parseError = (res) => {
    logger.log( "parseError", { res } );
    if ( _.has( res, 'message' ) ) {
        return Promise.resolve( { 
            code: res.code || 0, 
            message: res.message,
        } );

    } else if ( isJsonResponse( res ) ) {
        return Promise.resolve( res.json() )
            .then( json => ({
                code: res.code || 0,
                message: JSON.stringify(json),
                json: json,
            }))
            // -rx- .then( body => body.errors 
            // -rx-         ? _.first( body.errors )  // graphql errors
            // -rx-         : body.error 
            // -rx- )  
            // -rx- ;

    } else {
        return Promise.resolve( _.result( res, 'text' ) )
            .then( body => ({ 
                code: res.status || 0,
                message: _.defaultTo( body, res.statusText ),
            }))
            ;
    }
};

const mapResponseToPayload = (res): Promise<any> => {
    if ( isJsonResponse( res ) ) {
        return res.json();
    }
    if ( isPdfResponse( res ) ) {
        return res.blob();
    }
    return res.text();
};

/**
 * @return promise
 */
export const post = (url, data, headers ) => {
    logger.log( 'post.entry', { url, data, headers } );
    return fetch( url, {
            method: "POST",
            body: JSON.stringify( data || {} ),
            headers,
        })
        .then( throwIfNotOk )
        .then( mapResponseToPayload )
        .then( sf.tap( resJson => logger.log( 'post.response', { url, resJson, data, headers } ) ) )
        .catch( sf.thru_wait_throw( parseError ) )
        .catch( sf.tap_wait_throw( logError( { url, data, headers } ) ) )
        ;
};

/**
 * @return promise w/ response body as json
 */
export const get = (url, headers = {}) => {
    logger.log( 'get.entry', { url });
    return fetch( url, {
            method: "GET",
            headers,
        })
        .then( throwIfNotOk )
        .then( res => res.json() )
        .then( sf.tap( resJson => logger.log( 'get.response', { url, resJson } ) ) )
        .catch( sf.thru_wait_throw( parseError ) )
        .catch( sf.tap_wait_throw( logError( { url, headers } ) ) )
        ;
};

// -rx- const fetchWithTimeout = (url, options, timeout_s = 12 ) => {
// -rx-     return new Promise((resolve, reject, onCancel) => {
// -rx-         let isCancelled = false;
// -rx-         let isSatisfied = false;
// -rx-         const timer = setTimeout(
// -rx-             () => {
// -rx-                 logger.log( 'ERROR: fetchWithTimeout: TIMEOUT', { url, isSatisfied, isCancelled } )
// -rx-                 if ( isSatisfied || isCancelled ) {
// -rx-                     return null;
// -rx-                 } 
// -rx-                 isSatisfied = true;
// -rx-                 reject(new Error(`The operation timed out. Check your network connection and try again.`))
// -rx-             },
// -rx-             timeout_s * 1000
// -rx-         );
// -rx-         fetch( url, options )
// -rx-             .then( sf.tap( err => clearTimeout( timer ) ) ) 
// -rx-             .then( res => resolve(res) )
// -rx-             .catch( sf.tap_throw( err => clearTimeout( timer ) ) ) 
// -rx-             .catch( sf.tap_throw( err => logger.log( 'ERROR: fetchWithTimeout', { url, isSatisfied, isCancelled, err } ) ) )
// -rx-             .catch( err => {
// -rx-                 if ( isSatisfied || isCancelled ) {
// -rx-                     return null;
// -rx-                 }
// -rx-                 isSatisfied = true;
// -rx-                 reject(err);
// -rx-             })
// -rx-             ;
// -rx-         onCancel( () => { isCancelled = true; });
// -rx-     });
// -rx- };

export const is200SeriesStatusCode = (statusCode) => {
    const sc = _.toNumber( statusCode ) ;
    return _.isNumber( sc ) 
        ? (sc >= 200 && sc < 300)
        : false
        ;
};


export const isAuthzError = (err:any) => err && (err.code == 401 || err.code == 403 );
