/**
 * @rob4lderman
 * feb2020
 *
 * 
 */
import { combineReducers } from 'redux';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import {
    reduceReducers,
    actionReducer,
    payloadReducer,
    mergePayloadReducer,
} from './selectors';
import * as paragon from '../services/paragon';
import {
    sf,
    LoggerFactory,
} from '../utils';
import {
    ResetPasswordRequest,
    ResetPasswordResponse,
    ResetPasswordNewRequest,
    LoginRequest,
    LoginResponse,
    TestSummary,
    UserProfile,
    TestMethod,
    ValidateTestResponse,
    DraftTestSummary,
    ChangePasswordRequest,
    RegisterRequest,
} from '../types';
import _ from 'lodash';
import {
    ModelShape,
    Contact,
    EquipmentInfo,
    PalletInfo,
    CustomerInfo,
    GeneralInfo,
    FilmInfo,
    CatAnalysis,
    TestPayload,
    initCatAnalysis,
    AlertData,
    ParsedAuthToken,
} from './types';
import * as catModel from './catModel';

import handTestReducer from './handTest/reducers/index';

import { handTestInitialState } from './handTest/models/state';
import { getHandProducts, getHandTestSummariesThunk } from './handTest/actions';

const logger = LoggerFactory('model');
export { asIdStr } from './catModel';

export const initialState:ModelShape = {
    user: { hi: 'world' },
    userProfile: null,
    // -rx- authToken: null,
    parsedAuthToken: null,
    equipmentList: [],
    equipmentManufacturers: [], // -rx- mocks.equipmentManufacturers, // TODO: []
    equipmentTypes: [],
    productManufacturers: [],
    products: [],
    customerContactTypes: [],
    loadProfiles: [], // -rx- mocks.loadProfiles, // TODO: []
    testMethods: [],
    salesReps: [],
    catAnalyses: {
        currentStrId: null,
        catAnalysisMap: {}
    },
    isLoading: {},
    searchText: '',
    filterMyTests: false,
    alertData: {},
    changePasswordFormData: { // TODO: types
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null, 
    }, 
    testSummaries: [],
    testSummariesPagination: {      /// TODO: types
        page: 0, 
        rowsPerPage: 10 
    },
    testSummariesSorting: {
        sortFieldName: null,
        sortDirection: 'asc',
    },
    currentTestSummaryId: 0,
    signInEmail: null,
    handTest: handTestInitialState
};

export const ActionTypes = {
    createContact: 'Model.CreateContact',
    updateContactFast: 'Model.UpdateContactFast', 
    deleteContact: 'Model.DeleteContact',
    updateGeneralInfo: 'Model.UpdateGeneralInfo',
    updateCustomerInfo: 'Model.UpdateCustomerInfo', 
    updatePalletInfo: 'Model.UpdatePalletInfo', 
    updateEquipmentInfo: 'Model.UpdateEquipmentInfo', 
    updateFilmInfo: 'Model.UpdateFilmInfo', 
    updateTestMethod: 'Model.UpdateTestMethod',
    setCurrentCatAnalysis: 'Model.SetCurrentCatAnalysis',
    SetTestSummaries: 'Model.SetTestSummaries',
    MergeIsLoading: 'Model.MergeIsLoading',
    SetSearchText: 'Model.SetSearchText',
    SetUserProfile: 'Model.SetUserProfile',
    SetAlertData: 'Model.SetAlertData',
    SetChangePasswordFormData: 'Model.SetChangePasswordFormData',
    SetFilterMyTests: 'Model.SetFilterMyTests',
    SetTestSummariesPagination: 'Model.SetTestSummariesPagination',
    SetTestSummariesSorting: 'Model.SetTestSummariesSorting',
    SetCurrentTestSummaryId: 'Model.SetCurrentTestSummaryId',
    SetSignInEmail: 'Model.SetSignInEmail',
    SetParsedAuthToken: 'Model.SetParsedAuthToken',
};


/**
 * selectors, map state => {field}
 */
const selectTestSummaries = (state:any): TestSummary[] => state.model.testSummaries;
export const selectUserProfile = (state:any): UserProfile => state.model.userProfile;
const selectChangePasswordFormData = (state:any) => state.model.changePasswordFormData;
const selectGeneralInfo = (state:any): GeneralInfo => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), 'generalInfo' );
const selectCurrentTestSummaryId = (state:any): number => state.model.currentTestSummaryId ;
const selectCurrentTestSummary = (state:any): TestSummary => {
    const testSummaries:TestSummary[] = selectTestSummaries(state);
    const currentTestSummaryId:number = selectCurrentTestSummaryId(state);
    return catModel.memoizedFindTestSummaryById( testSummaries, currentTestSummaryId );
};
// TODO: const selectIsLoadingField = (fieldName:string) => (state:any): boolean => state.model.isLoading[fieldName] ;

/**
 * auth flows:
 * 1. loginThunk: regular login 
 * 2. resetPasswordNewThunk: reset password
 * 3. logoutThunk: regular logout
 * 4. onLaunchThunk: load from localstorage
 *      - must verify authtoken works before marking the user "logged in"
 *      - OR!!! detect 401's and redirect the user.
 * 
 * @param authToken 
 * @return Promise<authToken>
 */
const setAuthToken = (authToken:string, dispatch:any): Promise<string> => {
    paragon.setAuthToken( authToken );
    localStorage.setItem( 'access_token', authToken );
    const parsedAuthToken:ParsedAuthToken = parseAuthToken(authToken);
    dispatch( { type: ActionTypes.SetParsedAuthToken, payload: parsedAuthToken } );
    return Promise.resolve( authToken );
};

const getUserProfileThunk = (dispatch:any, getState:any): Promise<UserProfile> => {
    return paragon.getUserProfile()
        .then( sf.tap( (res:UserProfile) => dispatch( { type: ActionTypes.SetUserProfile, payload: res } ) ) )
        .then( sf.tap( (res:UserProfile) => dispatch( { type: ActionTypes.SetSignInEmail, payload: res.email } ) ) )
        ;
};

const updateUserProfileThunk = (userProfile:UserProfile) => (dispatch:any, getState:any): Promise<UserProfile> => {
    const isLoadingField = 'userProfile';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return paragon.updateUserProfile( userProfile )
        .then( sf.tap( () => dispatch( { type: ActionTypes.SetUserProfile, payload: userProfile } ) ) )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

const validateChangePasswordFormData = (changePasswordFormData:any) => {
    if ( _.isEmpty( changePasswordFormData.oldPassword ) ) {
        changePasswordFormData.oldPasswordError = 'Required';
    }
    if ( _.isEmpty( changePasswordFormData.newPassword ) ) {
        changePasswordFormData.newPasswordError = 'Required';
    }
    if ( changePasswordFormData.newPassword !== changePasswordFormData.confirmNewPassword ) {
        changePasswordFormData.confirmNewPasswordError = 'Passwords do not match';
    }
    return changePasswordFormData;
};

const isValidChangePasswordFormData = (changePasswordFormData:any): boolean => {
    return _.isEmpty( changePasswordFormData.oldPasswordError )
        && _.isEmpty( changePasswordFormData.newPasswordError )
        && _.isEmpty( changePasswordFormData.confirmNewPasswordError )
        ;
};

const changePasswordThunk = (dispatch:any, getState:any): Promise<any> => {
    const changePasswordFormData = validateChangePasswordFormData( selectChangePasswordFormData( getState() ) );
    if ( !!! isValidChangePasswordFormData( changePasswordFormData ) ) {
        dispatch({ type: ActionTypes.SetChangePasswordFormData, payload: changePasswordFormData });
        return Promise.reject(null);
    }
    const changePasswordRequest:ChangePasswordRequest = _.pick( selectChangePasswordFormData( getState() ), [ 'oldPassword', 'newPassword' ] );
    const isLoadingField = 'changePassword';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return paragon.changePassword( changePasswordRequest )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

const parseAuthToken = (authToken:string):ParsedAuthToken => {
    if (_.isEmpty(authToken)) {
        return null;
    }
    // -rx- logger.info('parseAuthToken: entry:', authToken);
    const parsedAuthToken:ParsedAuthToken = _.chain(_.nth(_.split( authToken, '.'),1))
        .thru( window.atob )
        .thru( JSON.parse )
        .value()
    // -rx- logger.info('parseAuthToken: parsed: ', parsedAuthToken);
    return parsedAuthToken;
};

/**
 * @return promise w/ { access_token }
 */
const loginThunk = (data:LoginRequest) => (dispatch:any, getState:any) : Promise<LoginResponse> => {
    return paragon.login( data )
        .then( sf.tap_wait( (res:LoginResponse) => setAuthToken( res.access_token, dispatch ) ) )
        .then( sf.tap_wait( () => paragon.setIsAuthz( true ) ) )
        .then( sf.tap_wait( () => dispatch( initSessionThunk ) ) )
        ;
};

/**
 * @return promise w/ { access_token }
 */
const registerThunk = (data:RegisterRequest) => (dispatch:any, getState:any) : Promise<LoginResponse> => {
    return paragon.register( data )
        .then( () => dispatch( loginThunk( { username: data.Email, password: data.Password })))
        ;
};

const fetchFormOptionsThunk = (dispatch:any, getState:any): Promise<any> => {
    return Promise.resolve(null)
        .then( sf.tap( _.partial( getEquipmentManufacturersThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getEquipmentListThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getEquipmentTypesThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getProductManufacturersThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getProductsThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getHandProducts, dispatch ) ) )
        .then( sf.tap( _.partial( getCustomerContactTypesThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getLoadProfilesThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getTestMethodsThunk, dispatch, getState ) ) )
        .then( sf.tap( _.partial( getSalesRepsThunk, dispatch, getState ) ) );
};

const getTestSummariesThunk = (dispatch:any, getState:any): Promise<any> => {
    const isLoadingField = 'testSummaries';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return paragon.getTestSummaries(1)
        .then( sf.tap( (val:TestSummary[]) => dispatch({ type: ActionTypes.SetTestSummaries, payload: val }) ) )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( logger.alertError ) // TODO: openAlertWindow
        ;
};

const getTestThunk = (testId:number) => (dispatch:any, getState:any): Promise<CatAnalysis> => {
    const isLoadingField = 'getTest';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return paragon.getTest(testId)
        .then( catModel.mapTestPayloadToCatAnalysis )
        .then( sf.tap( (catAnalysis:CatAnalysis) => logger.log( 'mapTestPayloadToCatAnalysis', { catAnalysis })))
        .then( sf.tap( (catAnalysis:CatAnalysis) => dispatch({ type: ActionTypes.setCurrentCatAnalysis, payload: catAnalysis }) ) )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

const createCatAnalysisThunk = (dispatch:any, getState:any): Promise<CatAnalysis> => {
    const strId:string = 'working'; // v4();
    const catAnalysis:CatAnalysis = initCatAnalysis(strId);

    return Promise.resolve( catAnalysis )
        .then( sf.tap( (catAnalysis:CatAnalysis) => dispatch({ type: ActionTypes.setCurrentCatAnalysis, payload: catAnalysis }) ) )
        ;
};

const getEquipmentManufacturersThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getEquipmentManufacturers()
        .then( sf.tap( (val:paragon.Manufacturer[]) => dispatch({ type: 'Model.SetEquipmentManufacturers', payload: val }) ) )
        ;
};

const getEquipmentListThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getEquipmentList()
        .then( sf.tap( (val:paragon.Equipment[]) => dispatch({ type: 'Model.SetEquipmentList', payload: val }) ) )
        ;
};

const getEquipmentTypesThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getEquipmentTypes()
        .then( sf.tap( (val:paragon.EquipmentType[]) => dispatch({ type: 'Model.SetEquipmentTypes', payload: val }) ) )
        ;
};

const getProductManufacturersThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getProductManufacturers()
        .then( sf.tap( (val:paragon.Manufacturer[]) => dispatch({ type: 'Model.SetProductManufacturers', payload: val }) ) )
        ;
};

const getProductsThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getProducts()
        .then( sf.tap( (val:paragon.Product[]) => dispatch({ type: 'Model.SetProducts', payload: val }) ) )
        ;
};

const getCustomerContactTypesThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getCustomerContactTypes()
        .then( sf.tap( (val:paragon.CustomerContactType[]) => dispatch({ type: 'Model.SetCustomerContactTypes', payload: val }) ) )
        ;
};

const getLoadProfilesThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getLoadProfiles()
        .then( sf.tap( (val:paragon.LoadProfile[]) => dispatch({ type: 'Model.SetLoadProfiles', payload: val }) ) )
        ;
};

const getTestMethodsThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getTestMethods()
        .then( sf.tap( (val:paragon.TestMethod[]) => dispatch({ type: 'Model.SetTestMethods', payload: val }) ) )
        ;
};

const getSalesRepsThunk = (dispatch:any, getState:any): Promise<any> => {
    return paragon.getSalesReps()
        .then( sf.tap( (val:paragon.SalesRep[]) => dispatch({ type: 'Model.SetSalesReps', payload: val }) ) )
        ;
};

/**
 * @return promise w/ null.
 */
const logoutThunk = (dispatch:any, getState: any): Promise<any> => {
    return paragon.logout()
        .then( sf.tap_wait( () => setAuthToken( '', dispatch ) ) )
        .then( sf.tap_wait( () => paragon.setIsAuthz( false ) ) )
        .then( sf.tap( () => dispatch( { type: 'Model.Reset' } ) ) )
        ;
};

/**
 * @return promise w/ nothing 
 */
const resetPasswordThunk = (data:ResetPasswordRequest) => (dispatch:any, getState:any) : Promise<ResetPasswordResponse> => {
    return paragon.resetPassword( data ) ;
};

/**
 * @return promise w/ session
 */
const resetPasswordNewThunk = (data:ResetPasswordNewRequest) => (dispatch:any, getState:any) : Promise<LoginResponse> => {
    return paragon.resetPasswordNew( data )
        .then( sf.tap_wait( (res:LoginResponse) => setAuthToken( res.access_token, dispatch ) ) )
        .then( sf.tap_wait( () => paragon.setIsAuthz( true ) ) )
        .then( sf.tap_wait( () => dispatch( initSessionThunk ) ) )
        ;
};

/**
 * @return resolved Promise if user is logged in; rejected Promise otherwise.
 */
const verifyAuthz = (): Promise<UserProfile> => {
    return paragon.getUserProfile();
};

const initSessionThunk = (dispatch:any, getState:any): Promise<UserProfile> => {
    return Promise.resolve(null)
        .then( () => dispatch( getUserProfileThunk ) )
        .then( sf.tap( () => dispatch( getTestSummariesThunk ) ) )
        .then( sf.tap( () => dispatch( getHandTestSummariesThunk ) ) )
        .then( sf.tap( () => dispatch( fetchFormOptionsThunk ) ) )
        ;
};

/**
 * @return promise w/ user session
 */
const onLaunchThunk = (dispatch:any, getState:any): Promise<any> => {
    const access_token = localStorage.getItem('access_token');
    logger.log( "onLaunchThunk", { access_token } );
    if ( _.isEmpty( access_token ) ) {
        return Promise.resolve( null );
    }
    return Promise.resolve( access_token )
        .then( (access_token:string) => setAuthToken( access_token, dispatch ) )
        .then( verifyAuthz )
        .then( sf.tap_wait( () => paragon.setIsAuthz( true ) ) )
        .then( () => dispatch( initSessionThunk ) )
        ;
};

/**
 * @return promise w/ ?
 */
const saveTestThunk = (draftId: number, catAnalysis:CatAnalysis) => (dispatch:any, getState:any) : Promise<DraftTestSummary> => {
    const isLoadingField = 'saveTest';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return Promise.resolve( catAnalysis )
        .then( catModel.mapCatAnalysisToTestPayload ) 
        .then( sf.tap( (testPayload:TestPayload) => logger.log( 'mapCatAnalysisToTestPayload', { catAnalysis, testPayload })))
        .then( _.partial( paragon.saveTest, draftId ) )
        .then( sf.tap_catch( () => dispatch( getTestSummariesThunk )))
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

/**
 * @return promise w/ cloned catAnalysis
 */
const cloneTestThunk = (draftId: number) => (dispatch:any, getState:any) : Promise<CatAnalysis> => {
    const isLoadingField = 'cloneTest';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return paragon.cloneTest( draftId )
        .then( catModel.mapTestPayloadToCatAnalysis )
        .then( sf.lens( 'strId' ).set( 'working' ) )
        .then( sf.tap( (catAnalysis:CatAnalysis) => logger.log( 'mapTestPayloadToCatAnalysis', { catAnalysis })))
        .then( sf.tap( (catAnalysis:CatAnalysis) => dispatch({ type: ActionTypes.setCurrentCatAnalysis, payload: catAnalysis }) ) )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

/**
 * @return promise w/ validation result
 */
const validateTestThunk = (catAnalysis:CatAnalysis) => (dispatch:any, getState:any) : Promise<ValidateTestResponse> => {
    const isLoadingField = 'validateTest';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return Promise.resolve( catAnalysis )
        .then( catModel.mapCatAnalysisToTestPayload ) 
        .then( sf.tap( (testPayload:TestPayload) => logger.log( 'validateTestThunk', { catAnalysis, testPayload })) )
        .then( paragon.validateTest )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

const validateHandTestThunk = (handTestAnalysis:any) => (catAnalysis:CatAnalysis) => (dispatch:any, getState:any) => {
    const isLoadingField = 'validateTest';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return Promise.resolve( { handTestAnalysis, catAnalysis } )
        .then( catModel.mapHandAnalysisToTestPayload ) 
        .then( paragon.validateTest )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};

/**
 * @return promise w/ pdf blob
 */
const generatePdfThunk = (draftId: number, catAnalysis:CatAnalysis) => (dispatch:any, getState:any) : Promise<any> => {
    const isLoadingField = 'generatePdf';
    dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: true } });
    return Promise.resolve( catAnalysis )
        .then( catModel.mapCatAnalysisToTestPayload ) 
        .then( _.partial( paragon.generatePdf, draftId ) )
        .then( sf.tap( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        .catch( sf.tap_throw( () => dispatch({ type: ActionTypes.MergeIsLoading, payload: { [isLoadingField]: false } }) ) )
        ;
};


//
// with* selectors
// 

export const withSessionState = (state:any) => ({
    parsedAuthToken: state.model.parsedAuthToken,
    hasRole: !!! _.isEmpty( _.get(state.model.parsedAuthToken, 'role')),
    // -rx- authToken: state.model.authToken,
    // -rx- isLoggedIn: !!! _.isEmpty( state.model.userProfile )
});

export const withSessionDispatch = (dispatch:any) => ({
    login: (data:LoginRequest) => dispatch( loginThunk( data ) ),
    logout: () => dispatch( logoutThunk ),
    register: (data:RegisterRequest) => dispatch( registerThunk( data ) ),
    resetPassword: (data:ResetPasswordRequest) => dispatch( resetPasswordThunk( data ) ),
    resetPasswordNew: (data:ResetPasswordNewRequest) => dispatch( resetPasswordNewThunk( data ) ),
    onLaunch: () => dispatch( onLaunchThunk ),
});
     
export const withUserState = (state:any) => ({
    user: state.model.user,
    userProfile: state.model.userProfile,
    testSummaries: state.model.testSummaries,
});

export const withUserDispatch = (dispatch:any) => ({
    updateUserProfile: (userProfile:UserProfile) => dispatch( updateUserProfileThunk(userProfile) ),
});

export const withTestSummariesState = (state:any) => ({
    testSummaries: state.model.testSummaries,
    isLoadingTestSummaries: state.model.isLoading.testSummaries,
});

export const withTestSummariesDispatch = (dispatch:any) => ({
    getTestSummaries: () => dispatch( getTestSummariesThunk ),
});

export const withFormOptionsState = (state:any) => ({
    ..._.pick( state.model, [
        'equipmentList',
        'equipmentManufacturers',
        'equipmentTypes',
        'productManufacturers',
        'products',
        'customerContactTypes',
        'loadProfiles',
        'testMethods',
    ])
});

export const withCatAnalysesState = (state:any) => ({
    catAnalyses: state.model.catAnalyses,
    currentCatAnalysis: catModel.getCurrentCatAnalysis( state.model.catAnalyses ),
});

export const withCatAnalysesDispatch = (dispatch:any) => ({
    createCatAnalysis: () => dispatch( createCatAnalysisThunk ),
    createContact: () => dispatch( { type: ActionTypes.createContact } ),
    deleteContact: (contact:Contact) => dispatch( { type: ActionTypes.deleteContact, payload: contact } ),
});

/**
 * 
 * @param selector the selector passed to redux useSelector
 * @param actionType the action to dispatch on the setter call
 */
export const useReduxState = <T>(selector:(state:any) => T, actionType:string): [ T, (newVal:T) => any ] => {
    const val:T = useSelector<any,T>( selector );
    const dispatch = useDispatch();
    const setVal = (newVal:T) => dispatch( { type: actionType, payload: newVal });
    return [ val, setVal ];
};

/**
 * HOOK.
 * Selector on currentCatAnalysis.generalInfo.
 * Behaves similarly to React.useState.
 */
export const useGeneralInfo = (): [ GeneralInfo, Function ] => {
    return useReduxState( 
        selectGeneralInfo, 
        ActionTypes.updateGeneralInfo
    );
};

/**
 * HOOK.
 */
export const useCustomerInfo = (): [ CustomerInfo, Function ] => {
    return useReduxState( 
        (state:any): CustomerInfo => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), 'customerInfo' ),
        ActionTypes.updateCustomerInfo,
    );
};

/**
 * HOOK.
 */
export const useContact = (contactStrId:string): [ Contact, Function ] => {
    return useReduxState( 
        (state:any): Contact => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), `contactsMap.${contactStrId}` ),
        ActionTypes.updateContactFast,
    );
};

/**
 * HOOK.
 */
export const useFilmInfo = (filmInfoStrId:string): [ FilmInfo, Function ] => {
    return useReduxState( 
        (state:any): FilmInfo => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), `filmInfoMap.${filmInfoStrId}` ),
        ActionTypes.updateFilmInfo,
    );
};

/**
 * HOOK.
 */
export const usePalletInfo = (): [ PalletInfo, Function ] => {
    return useReduxState( 
        (state:any): PalletInfo => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), 'palletInfo' ),
        ActionTypes.updatePalletInfo,
    );
};

/**
 * HOOK.
 */
export const useTestMethod = (): [ TestMethod, (val:TestMethod) => any ] => {
    return useReduxState( 
        (state:any): TestMethod => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), 'testMethod' ),
        ActionTypes.updateTestMethod,
    );
};

/**
 * HOOK.
 */
export const useEquipmentInfo = (): [ EquipmentInfo, Function ] => {
    return useReduxState( 
        (state:any): EquipmentInfo => _.get( catModel.getCurrentCatAnalysis( state.model.catAnalyses ), 'equipmentInfo' ),
        ActionTypes.updateEquipmentInfo,
    );
};

/**
 * HOOK.
 */
export const useSearchText = (): [ string, (a:string) => any ] => {
    return useReduxState( 
        (state:any): string => state.model.searchText,
        ActionTypes.SetSearchText,
    );
};

/**
 * HOOK.
 */
export const useSignInEmail = (): [ string, (a:string) => any ] => {
    return useReduxState( 
        (state:any): string => state.model.signInEmail,
        ActionTypes.SetSignInEmail,
    );
};

/**
 * HOOK.
 */
export const useFilterMyTests = (): [ boolean, (a:boolean) => any ] => {
    return useReduxState( 
        (state:any): boolean => state.model.filterMyTests,
        ActionTypes.SetFilterMyTests,
    );
};

/**
 * HOOK.
 * @return [ state, setterFunc ]
 */
export const useTestSummariesPagination = (): [ any, (a:any) => any ] => {
    return useReduxState( 
        (state:any): any => state.model.testSummariesPagination,
        ActionTypes.SetTestSummariesPagination,
    );
};

/**
 * HOOK.
 * @return [ state, setterFunc ]
 */
export const useTestSummariesSorting = (): [ any, (a:any) => any ] => {
    return useReduxState( 
        (state:any): any => state.model.testSummariesSorting,
        ActionTypes.SetTestSummariesSorting,
    );
};



/**
 * HOOK.
 */
export const useCurrentTestSummary = (): any => {
    const userProfile:UserProfile = useSelector( selectUserProfile );
    const currentTestSummary = useSelector( selectCurrentTestSummary );

    const isMyTestSummary:boolean = catModel.isTestSummaryCreatedBy( currentTestSummary, _.get( userProfile, 'email' ) );
    const dispatch = useDispatch();
    return {
        currentTestSummary,
        isMyTestSummary,
        setCurrentTestSummaryById: (id:number) => dispatch({ type: ActionTypes.SetCurrentTestSummaryId, payload: id }),
    };
};

/**
 * HOOK.
 */
export const useUserProfile = (): [ UserProfile, (val:UserProfile) => any] => {
    return useReduxState( 
        selectUserProfile,
        ActionTypes.SetUserProfile,
    );
};

/**
 * HOOK.
 */
export const useUpdateUserProfile = () => {
    const userProfile:UserProfile = useSelector<any,UserProfile>( (state:any): UserProfile => state.model.userProfile );
    const isLoadingUserProfile:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.userProfile );
    const dispatch = useDispatch();
    return {
        updateUserProfile: (): Promise<UserProfile> => dispatch( updateUserProfileThunk(userProfile) ) as unknown as Promise<UserProfile>,
        isLoadingUserProfile,
    };
};

/**
 * HOOK.
 */
export const useValidateTest = () => {
    const isLoadingValidateTest:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.validateTest );
    const dispatch = useDispatch();
    return {
        validateTest: (catAnalysis:CatAnalysis): Promise<ValidateTestResponse> => dispatch( validateTestThunk( catAnalysis ) ) as unknown as Promise<ValidateTestResponse>,
        isLoadingValidateTest,
    };
};

export const useValidateHandTest = () => {
    const isLoadingValidateTest:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.validateTest );
    const dispatch = useDispatch();
    return {
        validateHandTest: (handAnalysis:any) => (catAnalysis:CatAnalysis): Promise<ValidateTestResponse> =>
            dispatch(
                validateHandTestThunk( handAnalysis )( catAnalysis )
            ) as unknown as Promise<ValidateTestResponse>,
            isLoadingValidateTest,
    };
};

/**
 * HOOK.
 */
export const useSaveTest = () => {
    const isLoadingSaveTest :boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.saveTest );
    const dispatch = useDispatch();
    return {
        saveTest: (draftId: number, catAnalysis:CatAnalysis): Promise<DraftTestSummary> => dispatch( saveTestThunk( draftId, catAnalysis ) ) as unknown as Promise<DraftTestSummary>,
        isLoadingSaveTest,
    };
};

/**
 * HOOK.
 */
export const useGeneratePdf = () => {
    const isLoadingGeneratePdf:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.generatePdf );
    const dispatch = useDispatch();
    return {
        generatePdf: (draftId: number, catAnalysis:CatAnalysis): Promise<any> => dispatch( generatePdfThunk( draftId, catAnalysis ) ) as unknown as Promise<any>,
        isLoadingGeneratePdf,
    };
};

/**
 * HOOK.
 */
export const useCloneTest = () => {
    const isLoadingCloneTest:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.cloneTest );
    const dispatch = useDispatch();
    return {
        cloneTest: (draftId: number ): Promise<CatAnalysis> => dispatch( cloneTestThunk( draftId ) ) as unknown as Promise<CatAnalysis>,
        isLoadingCloneTest,
    };
};

/**
 * HOOK.
 */
export const useGetTest = () => {
    const isLoadingGetTest:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.getTest );
    const dispatch = useDispatch();
    return {
        getTest: (testId: number): Promise<CatAnalysis> => dispatch( getTestThunk(testId) ) as unknown as Promise<CatAnalysis>,
        isLoadingGetTest,
    };
};

/**
 * HOOK.
 */
export const useGetSalesReps = () => {
    const salesReps:paragon.SalesRep[] = useSelector<any,paragon.SalesRep[]>( (state:any): paragon.SalesRep[] => state.model.salesReps );
    const dispatch = useDispatch();
    return {
        getSalesReps: (): Promise<paragon.SalesRep[]> => dispatch( getSalesRepsThunk ) as unknown as Promise<paragon.SalesRep[]>,
        salesReps,
    };
};




/**
 * HOOK.
 */
export const useChangePassword = () => {
    const dispatch = useDispatch();
    const isLoadingChangePassword:boolean = useSelector<any,boolean>( (state:any): boolean => state.model.isLoading.changePassword );
    return {
        changePassword: (): Promise<any> => dispatch( changePasswordThunk ) as unknown as Promise<any>,
        isLoadingChangePassword,
    };
};


/**
 * HOOK.
 */
export const useChangePasswordFormData = () => {
    return useReduxState( 
        selectChangePasswordFormData, 
        ActionTypes.SetChangePasswordFormData
    );
};

/**
 * HOOK.
 */
export const useAlertWindow = () => {
    const alertData:AlertData = useSelector<any,any>( (state:any): boolean => state.model.alertData );
    const dispatch = useDispatch();
    const openAlertWindow = (alertData:AlertData) => dispatch( { type: ActionTypes.SetAlertData, payload: alertData } );
    return {
        openAlertWindowForError: (err:any) => openAlertWindow( mapErrorToAlertWindow( err ) ),
        openAlertWindow,
        closeAlertWindow: () => dispatch( { type: ActionTypes.SetAlertData, payload: {} } ),
        alertData,
    };
};

export const mapErrorToAlertWindow = (err:any):AlertData => {
    logger.log( 'mapErrorToAlertData', { err });
    
    if ( err.code >= 500 ){
        return { title: 'Unknown Error', text: 'Something bad happened... Try again, or maybe reload the app' };
    }

    if ( _.isNil( err ) ) {
        return {};
    }

    if ( _.isString(err) ) {
        return { title: 'Error', text: err };
    }

    if ( !!! _.isEmpty( _.get( err, 'title' ) ) && !!! _.isEmpty( _.get( err, 'text' ) ) ) {
        return err as AlertData;
    }

    if ( !!! _.isEmpty( _.get( err, 'json.error.message' ) ) ) {
        return { title: 'Error', text: _.get( err, 'json.error.message' ) };
    }

    if ( !!! _.isEmpty( _.get( err, 'message' ) ) ) {
        return { title: 'Error', text: _.get( err, 'message' ) };
    }

    return { title: 'Unknown Error', text: 'Something bad happened... Try again, or maybe reload the app' };
};


/**
 * The reducer.
 */
export default reduceReducers([
    actionReducer( 'Model.Reset', (state:any, action:any) => sf.lens('signInEmail').set(state.signInEmail)(initialState) ),
    combineReducers({
        user: actionReducer( 'Model.SetUser', payloadReducer ),
        userProfile: actionReducer( ActionTypes.SetUserProfile, payloadReducer ),
        searchText: actionReducer( ActionTypes.SetSearchText, payloadReducer, initialState.searchText ),
        filterMyTests: actionReducer( ActionTypes.SetFilterMyTests, payloadReducer ),
        testSummariesPagination: actionReducer( ActionTypes.SetTestSummariesPagination, payloadReducer ),
        testSummariesSorting: actionReducer( ActionTypes.SetTestSummariesSorting, payloadReducer ),
        currentTestSummaryId: actionReducer( ActionTypes.SetCurrentTestSummaryId, payloadReducer ),
        // -rx- authToken: actionReducer( 'Model.SetAuthToken', payloadReducer, ),
        parsedAuthToken: actionReducer( ActionTypes.SetParsedAuthToken, payloadReducer ),
        isLoading: actionReducer( ActionTypes.MergeIsLoading, mergePayloadReducer, initialState.isLoading ),
        testSummaries: actionReducer( ActionTypes.SetTestSummaries, payloadReducer ),
        equipmentList: actionReducer( 'Model.SetEquipmentList', payloadReducer ),
        equipmentManufacturers: actionReducer( 'Model.SetEquipmentManufacturers', payloadReducer ),
        equipmentTypes: actionReducer( 'Model.SetEquipmentTypes', payloadReducer ),
        productManufacturers: actionReducer( 'Model.SetProductManufacturers', payloadReducer ),
        products: actionReducer( 'Model.SetProducts', payloadReducer ),
        customerContactTypes: actionReducer( 'Model.SetCustomerContactTypes', payloadReducer ),
        loadProfiles: actionReducer( 'Model.SetLoadProfiles', payloadReducer ),
        testMethods: actionReducer( 'Model.SetTestMethods', payloadReducer ),
        salesReps: actionReducer( 'Model.SetSalesReps', payloadReducer ),
        alertData: actionReducer( ActionTypes.SetAlertData, payloadReducer ),
        changePasswordFormData: actionReducer( ActionTypes.SetChangePasswordFormData, payloadReducer ),
        signInEmail: actionReducer( ActionTypes.SetSignInEmail, payloadReducer ),
        catAnalyses: reduceReducers([
            actionReducer( ActionTypes.setCurrentCatAnalysis, catModel.setCurrentCatAnalysis ),
            actionReducer( ActionTypes.updateGeneralInfo, catModel.updateCatAnalysisFieldFast('generalInfo') ),
            actionReducer( ActionTypes.updateCustomerInfo, catModel.updateCatAnalysisFieldFast('customerInfo') ),
            actionReducer( ActionTypes.createContact, catModel.createContact ),
            actionReducer( ActionTypes.updateContactFast, catModel.updateContactFast ),
            actionReducer( ActionTypes.deleteContact, catModel.deleteContact ),
            actionReducer( ActionTypes.updatePalletInfo, catModel.updateCatAnalysisFieldFast('palletInfo') ),
            actionReducer( ActionTypes.updateEquipmentInfo, catModel.updateCatAnalysisFieldFast('equipmentInfo') ),
            actionReducer( ActionTypes.updateFilmInfo, catModel.updateFilmInfoFast ),
            actionReducer( ActionTypes.updateTestMethod, catModel.updateCatAnalysisFieldFast('testMethod') ),
        ], initialState.catAnalyses ),
        handTest: handTestReducer || (() => ({}))
    })
], initialState);


