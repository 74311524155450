/**
 * @rob4lderman
 * jul2020
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const AccountPendingAlert:React.FC<any> = (props:any) => {
    const {
        children,
        ...rprops
    } = props;
    return (
        <Box
            color="white"
            bgcolor="#de0b0b"
            p={1}
            mb={2}
            textAlign={'center'}
            borderRadius={4}
            >
            <Typography 
                component="h6" 
                variant="caption"
                {...rprops}
                >
                { children }
            </Typography>
        </Box>
    );
};

export default AccountPendingAlert;